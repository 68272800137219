import { RelocationBudgetDetails } from './../../../../core/models/relocation-budget.model';
import { GetRewardsComponent } from './../../get-rewards/get-rewards.component';
import { ManageMoveSharedService } from './../../../../core/services/manage-move-shared.service';
import { DatePipe } from '@angular/common';
import { InvitationSentComponent } from './../invitation-sent/invitation-sent.component';
import { Subscription } from 'rxjs';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as constants from '../../../../core/models/constants';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';


@Component({
  selector: 'app-rewards-cashback',
  templateUrl: './rewards-cashback.component.html',
  styleUrls: ['./rewards-cashback.component.scss']
})
export class RewardsCashbackComponent implements OnInit, OnDestroy {
  /** reward referal date */
  requestedDate: any;
  /** Subscription property for subscribing services */
  private readonly subscription: Subscription = new Subscription;
  /** candidate Data */
  candidateInfo: RelocationBudgetDetails;
  canGetRewards: any;

  constructor(public dialog: MatDialog,
    private readonly manageMoveSharedService: ManageMoveSharedService,
    public snackBar: MatSnackBar,
    private readonly permissionsSvc: PermissionsService,
    private readonly router: Router,
    private readonly datePipe: DatePipe,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.canGetRewards = this.permissionsSvc.getCapabilities();
    this.subscription.add(this.manageMoveSharedService.candidateDetails.subscribe(data => this.candidateInfo = data));
    if (this.candidateInfo.rewardRequestedDate) {
      this.requestedDate = this.datePipe.transform(this.candidateInfo.rewardRequestedDate, 'yyyy-MM-dd');
    }
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-REVIEW_CASHBACK', 'REVIEW_CASHBACK');
      });
  }

  /**Function To open reward referal dialog on click of Referal button */
  public openDialog(): void {
    const dialogRef = this.dialog.open(GetRewardsComponent, {
      panelClass: 'schedule-dialogMainContainer',
      disableClose: false,
      data: { canGetRewards: this.canGetRewards }
    });

    this.subscription.add(dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (constants.rewardsSuccessResponseMsg === response) {
          this.requestedDate = this.datePipe.transform(new Date(), constants.dateFormat);
          this.candidateInfo.rewardRequestedDate = this.datePipe.transform(new Date(), constants.dateFormat);
        }
        this.snackBar.openFromComponent(InvitationSentComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          data: response,
          duration: 5000
        });
      }
    }));
  }

  /**
   * Destroys the subscription object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
