import { Component, OnInit, Input } from '@angular/core';
import { RelocationBudgetDetails } from '../../../../core/models/relocation-budget.model';
import { TimelineService } from '../../../../core/models/timeline-service.model';
import { timelineDatesMock } from './../../../../../UnitTest-Support/Mocks/data.mock';
import { timelineDateDescriptions } from '../../../../core/models/constants';
import { ManageMoveSharedService } from '../../../../core/services/manage-move-shared.service';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';

/** base component for timeline */
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  /** Recieving transferee Details from parent component */
  @Input() transfereeDetails: RelocationBudgetDetails;

  /** used to store timeline details fetched from service call */
  @Input() timelineDetails: TimelineService[];

  /** services to display */
  timelineServicesDisplay: TimelineService[] = [];

  /** stores constant descriptions */
  descriptions = timelineDateDescriptions;

  /** stores cssClassNames. */
  classNames = ['timlineSection Pack', 'timlineSection Load', 'timlineSection StorageIn',
    'timlineSection StorageOut', 'timlineSection Delivery'];
  /**
   * Base Constructor
   */
  constructor(private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  /** Component Initial Calls and Initialization */
  ngOnInit() {
    for (const service of this.timelineDetails) {
      if (service.serviceName === `Service Delivery Complete`) {
        this.timelineDetails.splice(this.timelineDetails.indexOf(service), 1);
      }
    }
    this.timelineDetails = this.timelineDetails.filter(
      (timelineService) => timelineService.sortOrder > 0
    );
    this.timelineDetails.sort(
      (firstData, secondData) => (firstData.sortOrder > secondData.sortOrder) ? 1 : -1
    );
    this.timelineDetails.forEach(timelineService => {
      if (timelineService.isActualAvailable && timelineService.startDate !== null) {
        return;
      } else if (timelineService.endDate !== null) {
        this.timelineServicesDisplay.push(timelineService);
      }
    });
    this.mapTimelineServices();
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-TIMELINE', 'TIMELINE');
      });
  }

  /** function to map timeline services descriptions to the response received from API call */
  mapTimelineServices() {
    this.timelineServicesDisplay.forEach((timelineService) => {
      switch (timelineService.serviceName) {
        case `Job Start Date`: {
          timelineService.serviceName = `My Job Start Date`;
          timelineService.serviceDesc = this.descriptions[0].desc;
          timelineService.class = 'timlineSection JobStart';
          timelineService.icon = 'lock';
          break;
        }
        case `Estimated Pack Dates`: {
          timelineService.serviceName = `Estimated Pack Date`;
          timelineService.serviceDesc = this.descriptions[1].desc;
          timelineService.class = this.classNames[0];
          timelineService.icon = 'lock_open';
          break;
        }
        case `Estimated Load Dates`: {
          timelineService.serviceName = `Estimated Load Date`;
          timelineService.serviceDesc = this.descriptions[2].desc;
          timelineService.class = this.classNames[1];
          timelineService.icon = 'lock_open';
          break;
        }
        case `Estimated Storage-In Dates`: {
          timelineService.serviceName = `Estimated Storage In Date`;
          timelineService.serviceDesc = this.descriptions[3].desc;
          timelineService.class = this.classNames[2];
          timelineService.icon = 'lock_open';
          break;
        }
        case `Estimated Storage-Out Dates`: {
          timelineService.serviceName = `Estimated Storage Out Date`;
          timelineService.serviceDesc = this.descriptions[4].desc;
          timelineService.class = this.classNames[3];
          timelineService.icon = 'lock_open';
          break;
        }
        case `Estimated Delivery Dates`: {
          timelineService.serviceName = `Estimated Delivery Date`;
          timelineService.serviceDesc = this.descriptions[5].desc;
          timelineService.class = this.classNames[4];
          timelineService.icon = 'lock_open';
          break;
        }
        case `Actual Pack Dates`: {
          timelineService.serviceName = `Actual Pack Date`;
          timelineService.serviceDesc = this.descriptions[1].desc;
          if (timelineService.isActualAvailable &&
            new Date(timelineService.endDate).toISOString().substring(0, 10) < new Date().toISOString().substring(0, 10)) {
            timelineService.class = this.classNames[0];
            timelineService.icon = 'check';
          } else if (timelineService.isActualAvailable &&
            new Date(timelineService.endDate).toISOString().substring(0, 10) >= new Date().toISOString().substring(0, 10)) {
            timelineService.class = this.classNames[0];
            timelineService.icon = 'lock';
          }
          break;
        }
        case `Actual Load Dates`: {
          timelineService.serviceName = `Actual Load Date`;
          timelineService.serviceDesc = this.descriptions[2].desc;
          if (timelineService.isActualAvailable &&
            new Date(timelineService.endDate).toISOString().substring(0, 10) < new Date().toISOString().substring(0, 10)) {
            timelineService.class = this.classNames[1];
            timelineService.icon = 'check';
          } else if (timelineService.isActualAvailable &&
            new Date(timelineService.endDate).toISOString().substring(0, 10) >= new Date().toISOString().substring(0, 10)) {
            timelineService.class = this.classNames[1];
            timelineService.icon = 'lock';
          }
          break;
        }
        case `Actual Storage-In Dates`: {
          timelineService.serviceName = `Actual Storage In Date`;
          timelineService.serviceDesc = this.descriptions[3].desc;
          if (timelineService.isActualAvailable &&
            new Date(timelineService.endDate).toISOString().substring(0, 10) < new Date().toISOString().substring(0, 10)) {
            timelineService.class = this.classNames[2];
            timelineService.icon = 'check';
          } else if (timelineService.isActualAvailable &&
            new Date(timelineService.endDate).toISOString().substring(0, 10) >= new Date().toISOString().substring(0, 10)) {
            timelineService.class = this.classNames[2];
            timelineService.icon = 'lock';
          }
          break;
        }
        case `Actual Storage-Out Dates`: {
          timelineService.serviceName = `Actual Storage Out Date`;
          timelineService.serviceDesc = this.descriptions[4].desc;
          if (timelineService.isActualAvailable &&
            new Date(timelineService.endDate).toISOString().substring(0, 10) < new Date().toISOString().substring(0, 10)) {
            timelineService.class = this.classNames[3];
            timelineService.icon = 'check';
          } else if (timelineService.isActualAvailable &&
            new Date(timelineService.endDate).toISOString().substring(0, 10) >= new Date().toISOString().substring(0, 10)) {
            timelineService.class = this.classNames[3];
            timelineService.icon = 'lock';
          }
          break;
        }
        case `Actual Delivery Dates`: {
          timelineService.serviceName = `Actual Delivery Date`;
          timelineService.serviceDesc = this.descriptions[5].desc;
          if (timelineService.isActualAvailable &&
            new Date(timelineService.endDate).toISOString().substring(0, 10) < new Date().toISOString().substring(0, 10)) {
            timelineService.class = this.classNames[4];
            timelineService.icon = 'check';
          } else if (timelineService.isActualAvailable &&
            new Date(timelineService.endDate).toISOString().substring(0, 10) >= new Date().toISOString().substring(0, 10)) {
            timelineService.class = this.classNames[4];
            timelineService.icon = 'lock';
          }
          break;
        }
      }
    });
  }
}
