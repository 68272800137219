import { Component, OnInit, Input, Renderer2, ElementRef, ViewEncapsulation, HostListener } from '@angular/core';
import { CoreSpend, FlexSpend } from '../../../../core/models/candidate-budget-details.model';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { PopupPositionService } from '../../../../core/services/popup-position.service';
import { CloseScrollStrategyConfig } from '@angular/cdk/overlay/typings/scroll/close-scroll-strategy';
import { ComponentPortal } from '@angular/cdk/portal';
import { HtmlTooltipComponent } from '../../../../core/components/html-tooltip/html-tooltip.component';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';

@Component({
  selector: 'app-service-tile',
  templateUrl: './service-tile.component.html',
  styleUrls: ['./service-tile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceTileComponent implements OnInit {

  /** tooltip icon active class */
  tooltipIconActive = 'active';
  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;
  /** stores tooltip width */
  tooltipWidth: number;

  @Input() coreServices: CoreSpend;
  @Input() flexServices: Array<FlexSpend>;

  // Detect window size
  @HostListener('window:resize')
  windoSize() {

    const windowWidth = window.innerWidth;

    if (windowWidth < 960) {
      this.tooltipWidth = 204;
    } else {
      this.tooltipWidth = 350;
    }
  }
  constructor(
    private readonly overlay: Overlay,
    private readonly positionService: PopupPositionService,
    private readonly renderer: Renderer2,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService

  ) {
    this.coreServices = null;
    this.flexServices = null;
    this.windoSize();
  }

  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-SERVICE_TILE', 'SERVICE_TILE');
      });
  }

  openHtmlTooltip(serviceData: FlexSpend | CoreSpend, evt: MouseEvent): void {
    const infoIcon = (evt.currentTarget as Element).getElementsByClassName('info-icon');
    this.renderer.addClass(infoIcon[0], this.tooltipIconActive);
    const rect = (infoIcon[0]).getBoundingClientRect();
    const target = this.getPositionByEvents(rect);
    const element = new ElementRef(target);
    const positionStrategy = this.placeByPositionStrategy(element);
    const thresholdScroll: CloseScrollStrategyConfig = {
      threshold: 50
    };
    const overlayConfig = new OverlayConfig({
      width: this.tooltipWidth,
      panelClass: 'html-tooltip-pane',
      hasBackdrop: true,
      backdropClass: 'mat-backdrop-transparent',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(thresholdScroll),
      disposeOnNavigation: true
    });
    this.overlayRef = this.overlay.create(overlayConfig);
    const containerPortal = new ComponentPortal(
      HtmlTooltipComponent,
      null,
      this.positionService.createInjector({
        dataObj: serviceData,
        clientX: rect.left + (rect.right - rect.left) / 2,
        clientY: rect.top + (rect.bottom - rect.top) / 2,
        targetElem: infoIcon[0],
        overlayRef: this.overlayRef
      })
    );
    this.overlayRef.attach(containerPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
      this.renderer.removeClass(infoIcon[0], this.tooltipIconActive);
    });
    this.overlayRef.detachments().subscribe(() => {
      this.overlayRef.dispose();
      this.renderer.removeClass(infoIcon[0], this.tooltipIconActive);
    });
  }
  /** get the position by events of the target */
  getPositionByEvents(rect: ClientRect) {
    return {
      getBoundingClientRect: (): ClientRect => ({
        bottom: rect.bottom,
        height: rect.height,
        left: rect.left,
        right: rect.right,
        top: rect.top,
        width: rect.width
      }),
    };
  }
  /** place the pop up by position strategy
 * @param element element reference
 */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        },
        {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]);
  }
}
