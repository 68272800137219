import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CoreSpend, FlexSpend, CandidateBudgetDetails } from '../../../core/models/candidate-budget-details.model';
import * as constants from '../../../core/models/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { ManageMoveService } from '../../../core/services/manage-move.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { CashoutComponent } from '../cashout/cashout.component';
import { ScheduleMoveComponent } from '../schedule-move/schedule-move.component';
import { ManageMoveSharedService } from '../../../core/services/manage-move-shared.service';
import { RelocationBudgetDetails } from '../../../core/models/relocation-budget.model';
import { InvitationSentComponent } from '../right-maincontent/invitation-sent/invitation-sent.component';
import { LoggerService } from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';

/** base component for relocation budget details */
@Component({
  selector: 'app-relocation-budget-details',
  templateUrl: './relocation-budget-details.component.html',
  styleUrls: ['./relocation-budget-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RelocationBudgetDetailsComponent implements OnInit, OnDestroy {
  /** stores candidate budget details */
  candidateBudgetDetails: CandidateBudgetDetails;
  /** Stores all the core services */
  coreServices: CoreSpend;
  /** Stores all the flex services */
  flexServices: Array<FlexSpend>;
  /** Stores the total budget */
  totalBudgetAmount: number;
  /** Stores the cashout amount */
  cashoutAmount: number;
  /** Variable to % */
  percentage: string;
  /** Stores Chart lables */
  chartLabels: Array<string>;
  /**Stores chart data set */
  chartDataSetData: Array<number>;
  /**Stores chart border width */
  chartBorderWidth: number;
  /**Stores colors for data set */
  chartDataSetColor: Array<string>;
  /** Stores if the status of arcs */
  chartArcDisableStatus: Array<boolean>;
  /**used to store tittle for relocation budget*/
  templateString = {
    HEADING: 'My Relocation Budget',
    HEADING_SUMMARY: `Work with our Professional Van Line provider to begin planning and secure your move dates. Your Cash Out
    available should be used for move related expenses you may incur, please see the recommended services below.`,
    TOTAL_BUDGET_TITLE: 'Total Budget Amount:',
    CHART_TITLE: 'Breakdown by Services:',
    CASHOUT_TITLE: 'Amount Available for Cash Out:',
    CASHOUT_BTN: 'Cash Out',
    CORE_SERVICE_TITLE: 'Guaranteed Services',
    SCHEDULE_MOVE_BTN: 'Schedule My Move',
    FLEX_SERVICE_TITLE: 'Recommended Services for Cash Out',
    TAX_INFO_TITLE: 'Tax Information',
    CASHOUT_REQUESTED: `Cash Out requested date`
  };
  /** stores tax included message */
  taxIncludedMessage = constants.taxIncluded;
  /** stores tax not included message */
  taxNotIncludedMessage = constants.taxNotIncluded;
  /** flag to check if tax is enabled or disabled */
  taxEnabled;
  /** stores candidate move details */
  candidateMoveDetails: RelocationBudgetDetails;
  /** flag for move scheduled or not */
  moveScheduled: boolean;
  /** flag for cashout done or not */
  cashoutDone: boolean;
  /** variable to store cashout date */
  cashOutDate;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**
   * base constructor
   * @param route to access route param
   * @param spinner to display spinner
   * @param manageMoveService manage move service
   * @param snackBar to display snack bar
   * @param dialog to display dialog
   * @param manageMoveSharedService to get shared data
   */
  constructor(
    private readonly route: ActivatedRoute,
    private readonly spinner: NgxSpinnerService,
    private readonly manageMoveService: ManageMoveService,
    private readonly snackBar: MatSnackBar,
    public dialog: MatDialog,
    private readonly manageMoveSharedService: ManageMoveSharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService

  ) {
    this.manageMoveSharedService.candidateDetails.subscribe(
      candidateDetails => {
        this.candidateMoveDetails = candidateDetails;
        if (this.candidateMoveDetails.ScheduledMoveDate) {
          this.moveScheduled = true;
        }
        if (this.candidateMoveDetails.cashedOutDate) {
          this.cashoutDone = true;
          this.cashOutDate = this.candidateMoveDetails.cashedOutDate;
        }
      });
  }
  /** function to be called on init */
  ngOnInit() {
    this.spinner.show();
    this.route.paramMap.subscribe((params: ParamMap) => {
      const candidateId = params.get('candidateId');
      if (candidateId) {
        this.subscription.add(
          this.manageMoveService
            .getCandidateBudget(candidateId)
            .subscribe((candidateBudget: CandidateBudgetDetails) => {
              if (candidateBudget) {
                this.candidateBudgetDetails = candidateBudget;
                this.populateGuaranteedServices();
                this.populateRecommendedServices();
                this.totalBudgetAmount = this.candidateBudgetDetails.UserBudget;
                this.cashoutAmount = this.candidateBudgetDetails.UserBudget - Math.ceil(this.candidateBudgetDetails.coreBudgetTotal);
                this.taxEnabled = this.candidateBudgetDetails.isTaxEnabled;
                this.populateBudgetChart();
                this.coreServices = this.candidateBudgetDetails.coreBenefits;
                this.coreServices.budgetAmount = Math.ceil(this.candidateBudgetDetails.coreBenefits.budgetAmount);
                this.flexServices = this.candidateBudgetDetails.flexSpend;
                this.flexServices.forEach(service => {
                  if (service.budgetName === 'Storage') {
                    service.budgetAmount = Math.ceil(service.budgetAmount);
                  }
                });
                this.spinner.hide();
                this.loggedInUserService.getLoggedInUserDetails()
                  .subscribe(response => {
                    const userId: any = response.userId.replace(/ .*/, '');
                    this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-RELOCATION_BUDGET', 'RELOCATION_BUDGET');
                  });
              } else {
                this.spinner.hide();
                this.snackBar.open(
                  'We are unable to process your request at this time. Please try again later.',
                  undefined,
                  { duration: 3000 }
                );
              }
            })
        );
      } else {
        this.spinner.hide();
      }
    });
  }

  /** used to map core service name and description */
  populateGuaranteedServices() {
    this.candidateBudgetDetails.coreBenefits.budgetName = constants.coreServiceDesc.name;
    this.candidateBudgetDetails.coreBenefits.budgetDesc = constants.coreServiceDesc.desc;
  }

  /** used to map flex services names and descriptions */
  populateRecommendedServices() {
    this.candidateBudgetDetails.flexSpend.forEach(element => {
      const service = constants.flexServicesDesc.filter(data => data.id === element.budgetName);
      element.budgetName = service[0].name;
      element.budgetDesc = service[0].desc;
    });
  }

  /** used to populate chart data */
  populateBudgetChart() {
    this.percentage = constants.percentage;
    this.chartDataSetData = [
      (this.candidateBudgetDetails.UserBudget - this.candidateBudgetDetails.coreBudgetTotal),
      this.candidateBudgetDetails.coreBudgetTotal];
    this.chartBorderWidth = 2;
    if (this.candidateMoveDetails.ScheduledMoveDate === null && this.candidateMoveDetails.cashedOutDate === null) {
      this.chartDataSetColor = [`#6192D3`, `#40806A`];
      this.chartArcDisableStatus = [false, false];
    } else if (this.candidateMoveDetails.ScheduledMoveDate !== null && this.candidateMoveDetails.cashedOutDate === null) {
      this.chartDataSetColor = [`#6192D3`, `#B8D8CD`];
      this.chartArcDisableStatus = [false, true];
    } else if (this.candidateMoveDetails.ScheduledMoveDate === null && this.candidateMoveDetails.cashedOutDate !== null) {
      this.chartDataSetColor = [`#D0DDED`, `#40806A`];
      this.chartArcDisableStatus = [true, false];
    } else if (this.candidateMoveDetails.ScheduledMoveDate !== null && this.candidateMoveDetails.cashedOutDate !== null) {
      this.chartDataSetColor = [`#D0DDED`, `#B8D8CD`];
      this.chartArcDisableStatus = [true, true];
    }
  }
  /**
   * Loads the ScheduleMove Component in MatDialog
   */
  openDialog(componentName: string): void {
    if (componentName === 'ScheduleMove') {
      const dialogRef = this.dialog.open(ScheduleMoveComponent, {
        panelClass: 'schedule-dialogMainContainer',
        autoFocus: false,
        data: this.candidateMoveDetails
      });
      dialogRef.afterClosed()
        .subscribe(details => {
          if (details) {
            this.moveScheduled = true;
            this.populateBudgetChart();
            this.snackBar.openFromComponent(InvitationSentComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              data: `Request Sent`,
              duration: 5000
            });
          }
        });
    } else if (componentName === 'CashOut') {
      const dialogRef = this.dialog.open(CashoutComponent, {
        panelClass: 'cashout-dialogMainContainer',
        autoFocus: false,
        data: this.candidateMoveDetails
      });
      dialogRef.afterClosed()
        .subscribe(details => {
          if (details) {
            this.cashoutDone = true;
            this.populateBudgetChart();
            this.snackBar.openFromComponent(InvitationSentComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              data: details,
              duration: 5000
            });
          }
        });
    }
  }

  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
