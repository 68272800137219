import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { environment } from '../../../environments/environment';
import { TokenPayload } from '../models/tokenPayload.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  /** Variable to store the token  */
  private readonly authClient: OktaAuth;
  constructor( 
      private readonly appConfig: AppConfigService,
    ) {
      const authOpts: OktaAuthOptions = {
        clientId: `${environment.oktaClientId}`,
        issuer: `${environment.oktaUrl}`,
        authorizeUrl: `${environment.oktaUrl}/v1/authorize`,
        redirectUri: `${environment.oktaRedirectUri}`,
        postLogoutRedirectUri: `${environment.oktaRedirectUri}`,
        tokenManager: {
          storage: 'sessionStorage'
        }
      };
      this.authClient = new OktaAuth(authOpts);
  }

  public refreshSession() {
    return this.authClient.token.getWithoutPrompt().then(refreshedToken => {  
      console.log('getWithoutPrompt()', refreshedToken); // Leave this debug code in place
      return refreshedToken;
    }).catch(err => {
      console.error(err);
      return;
    });
  }

  /** Service call for login */
  async login(user: TokenPayload) {
    try {
      const data = {
        username: user.email,
        password: user.password
      };

      const transaction = await this.authClient.signIn(data);
      const tokens: any = await this.authClient.token.getWithoutPrompt({
        responseType: 'code',
        sessionToken: transaction.sessionToken
      });
      tokens.forEach(token => {
        if (token.idToken) {
          this.authClient.tokenManager.add('idToken', token);
        }
        if (token.accessToken) {
          localStorage.setItem('token', token.accessToken);
          this.authClient.tokenManager.add('accessToken', token);
        }
        return this.authClient;
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    return this.authClient.tokenManager.get('accessToken');
  }
}
/** Credetials to be sent with login service call  */
