import { Component, OnInit, Inject, ViewEncapsulation, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { ModalData } from '../../../core/services/popup-position.service';
import { TooltipContent } from '../../../core/models/tooltip-content.model';
import * as constants from '../../../core/models/constants';
import { LoggerService } from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';

@Component({
  selector: 'app-overlay-tooltip',
  templateUrl: './overlay-tooltip.component.html',
  styleUrls: ['./overlay-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OverlayTooltipComponent implements OnInit, AfterViewInit {

  // Help hint for the field
  displayObj: TooltipContent;

  // Tooltip position for the field
  tooltTipPos = 'hidden';

  // Tooltip arrow position for the field
  arrowLeftPos = '70px';

  // Tooltop content for each field as an array
  messages: TooltipContent[] = constants.tooltTip;

  /** Base constructor method
   * @param data to get the data through ModalData
   */
  constructor(@Inject(ModalData) public data: any, private readonly myElement: ElementRef,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.displayObj = this.getTooltipContent(this.data.keyString);
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-OVERLAY_TOOLTIP', 'OVERLAY_TOOLTIP');
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setTooltipPosition();
    }, 200);
  }

  /** To close the dialog */
  close(): void {
    this.data.overlayRef.dispose();
  }

  // Listen on keydown events on a document level
  @HostListener('document:keydown', ['$event']) private handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Tab') {
      this.close();
    }
  }
  /** To fetch tooltip message */
  getTooltipContent(index: string): TooltipContent {
    const message: TooltipContent[] = this.messages.filter(x => x.key === index);
    return message[0];
  }

  /** To calculate tooltip position */
  setTooltipPosition(): void {
    this.tooltTipPos = '';
    const yPos: number = this.data.clientY - this.myElement.nativeElement.offsetParent.offsetTop;
    const xPos: number = this.data.clientX - this.myElement.nativeElement.offsetParent.offsetLeft;
    if (yPos <= 0) {
      this.tooltTipPos += ' bottom right';
      this.arrowLeftPos = xPos > 0 ? (+xPos + 60) + 'px' : '70px';
    } else {
      this.arrowLeftPos = xPos > 0 ? (+xPos + 70) + 'px' : '70px';
    }
  }
}
