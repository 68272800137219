import { Router } from '@angular/router';
import { CandidateNeedsAssessmentSharedService } from '../core/services/candidateneeds-assessment-shared.service';
import { PermissionsService } from '../core/services/permissions.service';
import { AppConfigService } from '../core/services/app-config.service';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../core/services/permissions.service";
import * as i3 from "../core/services/candidateneeds-assessment-shared.service";
import * as i4 from "../core/services/app-config.service";
import * as i5 from "ngx-cookie-service";
export class AuthGuard {
    constructor(router, permissionService, candidateSrvc, appConfig, cookieService) {
        this.router = router;
        this.permissionService = permissionService;
        this.candidateSrvc = candidateSrvc;
        this.appConfig = appConfig;
        this.cookieService = cookieService;
    }
    canActivate(route, state) {
        return new Promise((resolve, reject) => {
            if (!window.navigator.userAgent.match(/(MSIE|Trident)/)) {
                this.candidateSrvc.getCandidateNeedsAssessmentDetails().then(resp => {
                    if (!!resp && !!resp.familyDetails && !resp.familyDetails.familyRelocationStatus) {
                        if (route.url[0].path !== 'candidate-assessment') {
                            this.router.navigate(['/candidate-assessment']);
                        }
                        else {
                            if (!this.appConfig.getConfig('byPassAuthorization') && !!route && !!route.data && !!route.data.capabilities) {
                                this.permissionService.checkAuthorization(route.data.capabilities).then(response => {
                                    if (response) {
                                        resolve(true);
                                    }
                                    else {
                                        this.router.navigate(['/contact-us']);
                                        resolve(false);
                                    }
                                });
                            }
                            else {
                                resolve(true);
                            }
                        }
                    }
                    else {
                        if (route.url[0].path === 'candidate-assessment') {
                            this.router.navigate(['/manage-move']);
                        }
                        else {
                            if (!this.appConfig.getConfig('byPassAuthorization') && !!route && !!route.data && !!route.data.capabilities) {
                                this.permissionService.checkAuthorization(route.data.capabilities).then(response => {
                                    if (response) {
                                        resolve(true);
                                    }
                                    else {
                                        this.router.navigate(['/contact-us']);
                                        resolve(false);
                                    }
                                });
                            }
                        }
                    }
                });
            }
            else {
                this.router.navigate(['/not-supported']);
            }
        });
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.PermissionsService), i0.ɵɵinject(i3.CandidateNeedsAssessmentSharedService), i0.ɵɵinject(i4.AppConfigService), i0.ɵɵinject(i5.CookieService)); }, token: AuthGuard, providedIn: "root" });
