import { Component, OnInit, HostListener, NgZone } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, SELECT_PANEL_INDENT_PADDING_X } from '@angular/material';
import { Router, NavigationEnd } from '@angular/router';
import { AppConfigService } from './core/services/app-config.service';
import { CandidateNeedsAssessmentService } from './core/services/candidateneeds-assessment.service';
import { CandidateNeedsAssessmentSharedService } from './core/services/candidateneeds-assessment-shared.service';
import { CandidateNeedsAssessment } from './core/models/candidateneeds-assessment.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionTimeoutComponent } from './public/transferee/session-timeout/session-timeout.component';
import { LoggedInUserService } from './core/services/loggedin-user-details.service';
import { PermissionsService } from './core/services/permissions.service';
import { AuthenticationService } from './core/services/authentication.service';

/** Base application component */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /** title of the view */
  title: string;

  /** Session Timeout */
  // MouseOver event
  lastX = 0;
  lastY = 0;
  idleTimeoutMinutes = 15; // Idle Session Timeout in Minutes
  idleWarningMsgMinutes = 1;
  idlelastActionKeyName = 'lastAction';
  pollInterval = 1000;
  warningMsg = 'You will be logged out in a minute due to inactivity'; // Warning Message
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  sessionTimeoutFlag = true;
  refreshTokenInterval: any;
  refreshTokenTimeout = 9;

  /**
   * Standard base constructor
   * @param matIconRegistry Instance of MatIconRegistry
   * @param domSanitizer Instance of DomSanitizer
   * @param cookieService Instance of CookieService
   * @param appConfig Instance of AppConfigService
   * @param router Instance of Router
   * @param candidateService Instance of CandidateNeedsAssessmentService
   * @param needsAssessmentSvc Instance of CandidateNeedsAssessmentSharedService
   * @param ngZone Instance of NgZone
   * @param snackbar Instance of MatSnackBar
   */
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly cookieService: CookieService,
    private readonly appConfig: AppConfigService,
    private readonly router: Router,
    private readonly authService: AuthenticationService,
    private readonly loggedInUserService: LoggedInUserService,
    private readonly candidateService: CandidateNeedsAssessmentService,
    private readonly needsAssessmentSvc: CandidateNeedsAssessmentSharedService,
    private readonly ngZone: NgZone,
    private readonly snackbar: MatSnackBar,
    private readonly permissionService: PermissionsService
  ) {
    matIconRegistry.addSvgIcon(
      'thumbs-up',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/yes-thumb.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'thumbs-down',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/no-thumb.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'personFilled',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/person_filled.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'person-two',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/person-two.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'person-three',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/person-three.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'person-four',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/person-four.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'own',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/own-icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'rent',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/rent.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'home',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/house.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'apartment',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/apartment.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'town',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/townhouse.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'realestate',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/Rewards-icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'notificationbell',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/notification-bell-icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'exploreicon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/baseline-near_me-24px.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'truck',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/truck.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'contact',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/Contact.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'infoicon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/Info_icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'lockicon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/Lock.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'notesicon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/Notes.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'profileIcon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/profile_icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'logoutIcon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/logout_icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'editIcon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/edit_icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'success-icon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/success-icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'checkListIcon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/check_list_icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'eye-icon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/Eye-icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'logoCartusWhite',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/logo_cartus_white.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'calenderMasked',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/calender_masked.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'logoMobilifyWhite',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/Mobilify-H-Logo.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'signOut',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/sign-out-icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'checkCircle',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/check_circle.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'checkcircleoutline',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/check_circle_outline.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'highvaluemenu',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/Transferee/menu_book-24px.svg'
      )
    );
  }

  /** Get cards for display and set view title */
  ngOnInit() {

    this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd && (window as any).ga) {
      //   // Google Analytics
      //   (window as any).ga('set', 'page', `${window.location.pathname}#${event.urlAfterRedirects}`);
      //   (window as any).ga('send', 'pageview');
      // }
      if (event instanceof NavigationEnd) {
        if ((<any>window).dataLayer) {
				  (<any>window).dataLayer.push({
					event: 'pageview',
					page: {
					  path: window.location.pathname + '#' + event.urlAfterRedirects
					}
				  });
				}
			  }
    });
    // if ((window as any).ga) {
    //   const googleAnalyticsConfig: { [key: string]: string } = this.appConfig.getConfig('google_analytics');
    //   (window as any).ga('create', googleAnalyticsConfig.key, 'auto'); // Start Google Analytics session
    // }

    const idleExpiretime = parseInt(this.cookieService.get('car-ses-time'), 10);
    const currTimeMs = new Date().getTime();
    if (!!idleExpiretime && currTimeMs > idleExpiretime) {
      this.router.navigate(['/logout']);
    }
    this.loggedInUserService.getLoggedInUserDetails().subscribe(async response => {
      sessionStorage.setItem('car-ses-con', response.userId);
      await this.permissionService.getRoleCapabilities(response.userId)
      this.candidateService.getCandidateNeedsAssessment().subscribe((resp: CandidateNeedsAssessment) => {
        if (resp) {
          this.needsAssessmentSvc.updateCandidateNeedsAssesment(resp);
        }
      });
    });

    this.title = 'Angular App';
    this.refreshsessionInApp();
    this.startIdleTimeCountDown();
    if (!window.navigator.userAgent.match(/(MSIE|Trident)/)) {
      /*Checking for cookies availability else redirect to login*/
      const sessionCookie: string = this.cookieService.get('car-ses-tok');
      if (sessionCookie == null || sessionCookie.length === 0) {
        this.logout();
      }
    } else {
      this.router.navigate(['not-supported']); // redirect to non-supported url.
    }
  }

  /** Token refresh */
  public refreshsessionInApp() {
    this.refreshTokenInterval = setInterval(() => {
      this.authService.refreshSession().then((freshToken: any) => {
        if(freshToken){
          this.cookieService.set(
            'car-ses-tok',
            freshToken.tokens.accessToken.value,
            null, // We are relying on Okta session expiration
            '/',
            '.cartus.com',
            true
            );

          this.cookieService.set('car-token-claims',
            freshToken.tokens.idToken.claims ? JSON.stringify(freshToken.tokens.idToken.claims) : undefined,
            null,
            '/',
            '.cartus.com',
            true);

          this.cookieService.set('car-token-expiresat',
            freshToken.tokens.idToken.expiresAt,
            null,
            '/',
            '.cartus.com',
            true);

          this.cookieService.set(
            'car-token-idtoken',
            freshToken.tokens.idToken.idToken,
            null,
            '/',
            '.cartus.com',
            true
            );
        }
      })
      .catch((err) => {
          console.log('error in freshToken :', err);
      });
    }, 60000 * this.refreshTokenTimeout);
  }

  // Session Timeout
  /** Listen for mouse events */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    if (this.sessionTimeoutFlag && (e.pageX !== this.lastX || e.pageY !== this.lastY)) {
      this.lastX = e.pageX;
      this.lastY = e.pageY;
      this.refreshIdleCookie();
    }
  }

  /** logout of the application.  Logout URL handled by configuration */
  // logout() {
  //   sessionStorage.clear();
  //   const logoutURL = this.appConfig.getConfig('login')
  //   this.router.navigate(['/externalRedirect', { externalUrl: logoutURL }], {
  //     skipLocationChange: true
  //   });
  // }

  /** Listen for keypress events */
  @HostListener('document:keypress', ['$event'])
  onKeyPress() {
    if (this.sessionTimeoutFlag) {
      this.refreshIdleCookie();
    }
  }

  /** Start idle timeout feature */
  startIdleTimeCountDown() {
    this.refreshIdleCookie();
    this.ngZone.runOutsideAngular(() => {
      setTimeout(this.checkIdle.bind(this), this.pollInterval);
    });
  }

  /** Refresh idle timeout cookie */
  refreshIdleCookie() {
    if (this.sessionTimeoutFlag) {
      const idleExpireMs = (new Date().getTime() + (this.idleTimeoutMinutes * 60000) - 500);
      sessionStorage.setItem(this.idlelastActionKeyName, idleExpireMs.toString());
    }
  }
  /** Check idle timeout status */
  checkIdle() {
    this.ngZone.run(() => {
      const idleExpireMs = parseInt(sessionStorage.getItem(this.idlelastActionKeyName), 10);
      const idleWarningMsgTime = (idleExpireMs - (this.idleWarningMsgMinutes * 60000) - 500);
      const currTimeMs = new Date().getTime();
      if (currTimeMs > idleExpireMs) {
        this.snackbar.dismiss();
        this.logout();
      } else {
        if ((new Date(currTimeMs).toString() === new Date(idleWarningMsgTime).toString())) {
          this.sessionTimeoutFlag = false;
          this.snackbar.openFromComponent(SessionTimeoutComponent, {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: this.warningMsg
          }).onAction().subscribe(data => {
            this.sessionTimeoutFlag = true;
            this.refreshIdleCookie();
          });
        }
        this.ngZone.runOutsideAngular(() => {
          setTimeout(this.checkIdle.bind(this), this.pollInterval); // Check for one second
        });
      }
    });
  }

  logout() {
    this.router.navigate(['logout']); // Redirect to Login page
  }
  // Session Timeout
  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then();
  }
}

