import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ScheduleMoveComponent } from '../schedule-move/schedule-move.component';
import { ManageMoveSharedService } from '../../../core/services/manage-move-shared.service';
import { RelocationBudgetDetails } from '../../../core/models/relocation-budget.model';
import { TimelineService } from '../../../core/models/timeline-service.model';
import { LoggerService } from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';

/** right navigation */
@Component({
  selector: 'app-right-maincontent',
  templateUrl: './right-maincontent.component.html',
  styleUrls: ['./right-maincontent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RightMaincontentComponent implements OnInit {
  /** Used to hold Candidate NeedsAssesment Data */
  candidateMoveDetails: RelocationBudgetDetails;
  /** show/hide toast */
  showNotification = true;
  /**
   * Base constructor
   * @param manageMoveSharedService Move details shared service
   */
  constructor(
    private readonly manageMoveSharedService: ManageMoveSharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService

  ) { }

  /**Intilising the method */
  ngOnInit() {
    this.manageMoveSharedService.candidateDetails.subscribe(candidateDetails => {
      this.candidateMoveDetails = candidateDetails;
    });
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-RIGHT_MAINCONTENT', 'RIGHT_MAINCONTENT');
      });
  }

  /** close toast */
  closeNotification() {
    this.showNotification = false;
  }
}
