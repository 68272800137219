<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
  <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>
<div class="header" fxLayout="row" fxLayout.xs="column" fxFlexFill>
  <div class="header-title" fxFlex="25" fxLayoutAlign="space-around center" fxLayoutAlign.xs="space-around left"
    fxLayoutAlign.sm="space-around left" fxLayout.xs="column" fxFlex.xs="100%" fxFlex.sm="100%">
    <mat-sidenav-container fxHide fxShow.xs fxShow.sm class="example-container" (backdropClick)="close('backdrop')"
      *ngIf="shouldRun">
      <mat-sidenav #sidenav (keydown.escape)="close('escape')" disableClose>
        <div>
          <div class="mobileHeader">
            <div class="userInfo">
              <div class="userMobName" tabindex="0" aria-label="User Info">
                <span class="shortName">{{loggedInUserInitials}}</span>
                <span class="userName">{{loggedInUserName}}</span>
              </div>
              <div><button mat-button (click)="close('toggle button')"><em class="material-icons">close</em></button>
              </div>
            </div>
          </div>

          <div>
            <ul>
              <li *ngIf="transfereeDetails.isAuthorized"
                [routerLink]="['/manage-move/relocation-budget',transfereeDetails.candidateId]"
                routerLinkActive="router-link-active"><a href="javascript:void(0)" (click)="close('toggle button')">
                  <em class="material-icons side-nav-icon">attach_money</em>
                  Relocation Budget </a></li>
              <li [routerLink]="['/manage-move/rewards']" routerLinkActive="router-link-active"><a
                  href="javascript:void(0)" (click)="close('toggle button')">
                  <mat-icon class="realestate" svgIcon="realestate"></mat-icon> My Real Estate Rewards
                </a></li>
              <li><a class="exploreLink" (click)="close('toggle button')"
                  href="http://www.onboardnavigator.com/1.5/webContent/OBWC_Results.aspx?AID=106" target="_blank">
                  <mat-icon class="explore" svgIcon="exploreicon"></mat-icon>Explore Your Destination
                </a><a (click)="close('toggle button')"
                  href="http://www.onboardnavigator.com/1.5/webContent/OBWC_Results.aspx?AID=106" target="_blank"><img
                    src="../../../../../../assets/images/Transferee/24px (13).svg" alt="Transferee" /></a></li>
              <li *ngIf="transfereeDetails.hasHighValueGoods" [routerLink]="['/manage-move/high-value-goods',transfereeDetails.candidateId]"
                routerLinkActive="router-link-active">
                <a href="javascript:void(0)" (click)="close('toggle button')">
                  <img src="../../../../../../assets/images/Transferee/truck.svg" aria-hidden="true"/>
                  High Value Goods
                </a>
              </li>
              <li *ngIf="transfereeDetails.isAuthorized" [routerLink]="['/manage-move/timeline-checklist']"
                routerLinkActive="router-link-active"><a href="javascript:void(0)" (click)="close('toggle button')"><em
                    class="material-icons cartus_accent_dark_blue side-nav-icon">timeline</em> Timeline &
                  Tasks</a></li>
            </ul>
          </div>
          <div class="profileBorder">
            <ul>
              <li [routerLink]="['/manage-move/my-profile',transfereeDetails.candidateId]"
                routerLinkActive="router-link-active">
                <a href="javascript:void(0)" (click)="close('toggle button')">
                  <em class="material-icons side-nav-icon">person</em>
                  <span *ngIf="!isRedirectedFromAlphaPortal">My Profile</span>
                  <span *ngIf="isRedirectedFromAlphaPortal">User Profile</span>
                </a>
              </li>
              <li [routerLink]="['/manage-move/faq']" routerLinkActive="router-link-active"><a href="javascript:void(0)"
                  (click)="close('toggle button');">
                  <em class="material-icons side-nav-icon">help</em>Help
                </a></li>
              <li [routerLink]="[isRedirectedFromAlphaPortal ? '' : '/logout']" routerLinkActive="router-link-active">
                <a href="javascript:void(0)"
                  (click)="isRedirectedFromAlphaPortal ? exitTransfereeView() : close('toggle button')">
                  <em class="material-icons side-nav-icon">exit_to_app</em>
                  <span *ngIf="!isRedirectedFromAlphaPortal">Logout</span>
                  <span *ngIf="isRedirectedFromAlphaPortal">Exit Transferee View</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <div fxLayout="row" fxLayoutAlign="start center"><span class="hamburger-btn" mat-button
            (click)="sidenav.open(); addMenuClass()"><em class="material-icons">menu</em></span>
          <mat-icon class="transferee-logo" svgIcon="logoMobilifyWhite"></mat-icon>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
    <div fxLayout="row" fxShow fxHide.xs fxHide.sm fxFlex fxLayoutAlign="start center">
      <mat-icon class="transferee-logo" svgIcon="logoMobilifyWhite"></mat-icon>
    </div>
  </div>

  <div class="header-content" fxFlex="75" fxLayoutAlign="end center" fxLayout.xs="column" fxHide.xs fxHide.sm
    fxFlex.xs="100%" fxFlex.sm="100%" fxFlexFill>
    <a class="help" [routerLink]="['/manage-move/faq']" title="Help">
      <mat-icon matPrefix title="FAQ" aria-label="Help">help</mat-icon>
    </a>
    <div class="userInfo" aria-label="UserInfo">
      <span class="shortName" *ngIf="!isRedirectedFromAlphaPortal">{{loggedInUserInitials}}</span>
      <span class="shortName" *ngIf="isRedirectedFromAlphaPortal">
        <img src="../../../../../../assets/images/Transferee/visibility-24px.svg" aria-hidden="true"/>
      </span>
      <span class="userName"> {{loggedInUserName}}
        <button class="userInfomenuBtn" aria-label="User settings" mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon class="material-icons drop-down-ico">arrow_drop_down
          </mat-icon>
        </button>
      </span>
    </div>
  </div>
</div>

<mat-menu class="user-opt-drop-down header-menu {{readonlyActive}}" #menu="matMenu">
  <ng-container *ngFor="let item of menuItems">
    <button mat-menu-item [routerLink]="[item.link]" (click)="item.title === 'Exit Transferee View' ? exitTransfereeView() : null">
      <mat-icon class="menu-item-icon" svgIcon={{item.icon}}></mat-icon>
      <span class="menu-item-txt">{{item.title}}</span>
    </button>
  </ng-container>
</mat-menu>