import { PersistenceService, StorageType } from 'angular-persistence';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import {
  CandidateNeedsAssessment,
  ResidenceDetails
} from '../../../../core/models/candidateneeds-assessment.model';
import * as constants from '../../../../core/models/constants';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';

/**
 * Base component for Property-details component
 */
@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit, AfterViewInit {

  /**
   * to disable the next button by default
   */
  enableHomeStepButton = false;
  enableRoomStepButton = false;

  currentResidenceOwn = constants.currentResidenceOwn;
  currentResidenceRent = constants.currentResidenceRent;

  typeOfHomeHouse = constants.typeOfHomeHouse;
  typeOfHomeApartment = constants.typeOfHomeApartment;
  typeOfHomeTownhouse = constants.typeOfHomeTownhouse;
  /**
   * stores the cnadidate details who needs assessment
   */
  @Input() needsAssessment: CandidateNeedsAssessment;

  @Input() step: number;

  /**
   * Emitting the previous page step from child to parent
   */
  @Output() previousStep: EventEmitter<number> = new EventEmitter<number>();

  /**
   * emits the candidate assessment details
   */
  @Output() notify: EventEmitter<CandidateNeedsAssessment> = new EventEmitter<
    CandidateNeedsAssessment
  >();

  /**
   * Back button to be focused first
   */
  @ViewChild('backButton', {static : false}) backButton: ElementRef;

  roomsForm: FormGroup;
  count = 1;
  /**
   * used to inject dependencies
   * @param persistenceService Instance PersistenceService
   */
  constructor(private readonly formBuilder: FormBuilder,
    private readonly persistenceService: PersistenceService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) {
    this.roomsForm = this.formBuilder.group({
      Count: ['', Validators.required]
    });
  }
  /**
   * assign the residence details when component is initialized
   */
  ngOnInit() {
    // assigns residence details if selected
    if (!this.needsAssessment.residenceDetails) {
      this.needsAssessment.residenceDetails = {} as ResidenceDetails;
    }

    if (this.step === 5) {
      if (this.needsAssessment.residenceDetails.ownerStatus) {
        this.enableHomeStepButton = true;
      } else {
        this.enableHomeStepButton = false;
      }
    }

    if (this.step === 6) {
      if (this.needsAssessment.residenceDetails.homeType) {
        this.enableRoomStepButton = true;
      } else {
        this.enableRoomStepButton = false;
      }
    }

    if (this.step === 7) {
      if (this.needsAssessment.residenceDetails.noOfRooms) {
        this.roomsForm.get('Count').setValue(this.needsAssessment.residenceDetails.noOfRooms);
      } else {
        this.roomsForm.get('Count').setValue(this.count);
      }
    }
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-PROPERTY_DETAILS', 'PROPERTY_DETAILS');
      });
  }
  /**
   * Store the current step in session
   */
  ngAfterViewInit(): void {
    this.persistenceService.set('currentStep', JSON.stringify(this.step),
      { type: StorageType.SESSION });
      setTimeout( () => {
        this.backButton.nativeElement.focus();
      }, 1000);
  }
  /**
   * stores the owner status as own if selected as Own
   */
  onValueSelection(selectedValue: string): void {
    if (this.step === 5) {
      this.needsAssessment.residenceDetails.ownerStatus = selectedValue;
      this.enableHomeStepButton = true;
    } else {
      this.needsAssessment.residenceDetails.homeType = selectedValue;
      this.enableRoomStepButton = true;
    }
  }
  /**
   * increment the count of rooms on click and assign the value to count
   * */
  incNum() {
    // increments the count if count is less than maxRoomLimit
    if (this.count < constants.maxRoomLimit) {
      this.count = this.count + 1;
      this.roomsForm.get('Count').setValue(this.count);
    }
  }
  /**
   * Decrement the count value on click and assigns the value to count
   */
  decNum() {
    // decremenrs count value only if count greater than 1
    if (this.count > 1) {
      this.count = this.count - 1;
      this.roomsForm.get('Count').setValue(this.count);
    }
  }
  /**
   * emits the value to parent component
   */
  emit() {
    if (this.step === 7) {
      this.needsAssessment.residenceDetails.noOfRooms = this.roomsForm.get(
        'Count'
      ).value;
    }
    if (this.step > this.needsAssessment.completedStep) {
      this.needsAssessment.completedStep = this.step;
    }
    this.notify.emit(this.needsAssessment);
  }
  /**
   * To emit the previous step value to parent
   */
  previousEmit() {
    this.previousStep.emit(this.step - 1);
  }
}
