import { Address } from './../../../core/models/address.model';
import { Cashout } from './../../../core/models/cashout.model';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageMoveSharedService } from './../../../core/services/manage-move-shared.service';
import { ManageMoveService } from './../../../core/services/manage-move.service';
import { RelocationBudgetDetails } from './../../../core/models/relocation-budget.model';
import { Subscription, timer } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import * as constants from '../../../core/models/constants';
import { LoggerService } from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';
import { BankInfo } from 'src/app/core/models/bankInfo.model';
import { switchMap, map } from 'rxjs/operators';
import { PermissionsService } from 'src/app/core/services/permissions.service';


/**
 * Used to store the errormessages
 */
export const errorMessages: { [key: string]: string } = {
  AccountHolderName: 'You must enter Account Holder Name',
  SSN: 'You must enter SSN #',
  RoutingNumber: 'You must enter Routing #',
  BankNotFound: 'No bank was found for the entered Routing #',
  AccountNo: 'You must enter Account #',
  ConfirmAccountNo: 'You must confirm Account #',
  AccountNoMatch: 'Account # must match',
  SSNValidity: 'Special characters are not allowed',
  Street: 'You must enter Street address',
  City: 'You must enter City',
  State: 'You must enter State',
  ZIP: 'You must enter ZIP code',
  splCharacterValidation: 'Special characters are not allowed.',
  characterValidation: 'You must enter valid SSN #'
};

@Component({
  selector: 'app-cashout',
  templateUrl: './cashout.component.html',
  styleUrls: ['./cashout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CashoutComponent implements OnInit, OnDestroy {

  /** Form group name */
  cashoutForm: FormGroup;
  /** Account No Match */
  isAccountNoMatch = true;
  /**used to store currency symbol */
  currencySymbol: string;
  /** form title */
  title = 'Cash Out';
  /** state list from contamt file*/
  stateList = constants.stateList;
  /** Object Holds the candidate cashout details */
  cashoutModel: Cashout;
  /** Object Holds the candidate cashout address details */
  address: Address;
  /** Used to refer to errorMessages */
  errors = errorMessages;
  canCashOut: any;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription[] = [];

  constructor(private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CashoutComponent>,
    @Inject(MAT_DIALOG_DATA) public transfereeDetails: RelocationBudgetDetails,
    private readonly manageMoveService: ManageMoveService,
    private readonly manageMoveSharedService: ManageMoveSharedService,
    private readonly spinner: NgxSpinnerService,
    private readonly permissionsSvc: PermissionsService,
    private readonly dataPipe: DatePipe, private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.canCashOut = this.permissionsSvc.getCapabilities();
    this.currencySymbol = constants.currency;
    this.initializeCashoutForm();
    this.cashoutForm.controls['BankName'].disable();
    this.cashoutForm.controls['ConfirmAccountNo'].disable();
    this.subscription.push(this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-CASHOUT', 'CASHOUT');
      }));
  }

  /**
   * Used for initializing Cash out Form
   */
  initializeCashoutForm(): void {
    this.cashoutForm = this.formBuilder.group({
      AccountHolderName: ['', Validators.required, this.noEmptySpaceValidator],
      SocialSecurityNo: ['', [Validators.required, Validators.minLength(9),
                              this.regexValidator(new RegExp(/[a-zA-Z]/g), { 'alphabets': true }),
                              this.regexValidator(new RegExp(/[!@#$%^&*()/\\?,.?":{}\-\+=_|<>;'`~\] ]/g), { 'splcharacter': true })]],
      RouteNo: ['', [Validators.required], this.bankValidator.bind(this)],
      BankName: [''],
      BankShortName: [''],
      Street: ['', Validators.required, this.noEmptySpaceValidator],
      City: ['', Validators.required, this.noEmptySpaceValidator],
      State: ['', Validators.required, this.noEmptySpaceValidator],
      ZIP: ['', Validators.required, this.noEmptySpaceValidator],
      AccountNo: ['', Validators.required, this.noEmptySpaceValidator],
      ConfirmAccountNo: ['']      
    });
    this.cashoutForm.get('ConfirmAccountNo').setValidators([Validators.required,
      this.isConfirmMatch('AccountNo', 'ConfirmAccountNo')]);
    
  }
  /**
   * validator method to confirm two cotrol values are equal
   * @param controlName control name
   * @param confirmControlName confirmation control name
   */
  isConfirmMatch(controlName: string, confirmControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const data = control.parent.controls[controlName].value;
      if (data === control.parent.controls[confirmControlName].value) {
        control.parent.controls[confirmControlName].setErrors(null);
        return null;
      }

      control.parent.controls[confirmControlName].setErrors({ 'matchFailed': true });
      control.parent.controls[confirmControlName].markAsTouched();
      return { 'matchFailed': true };
    };
  }

/**
 * used to check wether input feild has empty space
 * @param input form with required fields
 */
  noEmptySpaceValidator(input: AbstractControl): ValidationErrors | null {
    return new Promise((resolve, reject) => {
      if (input.value.trim() === '') {
        resolve({ required: true });
      } else {
        resolve(null);
      }
    });
  }

  canSubmitCashOut() {
    return this.cashoutForm.valid && this.canCashOut;
  }

  /**
    * Regex validation.Return true if value matches with given regex
    * @param regex Regular expression
    * @param error instence of ValidationErrors
    */
  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value.match(regex);
      return valid ? error : null;
    };
  }

  /**
   * method to enable the control as editable based on another control data
   * @param fieldName control name
   * @param confirmFiledName to enable the control based on fieldName
  */
  isConfirmEditable(fieldName: string, confirmFiledName: string): void {
    if (this.cashoutForm.controls[fieldName].status === 'INVALID') {
      this.cashoutForm.controls[confirmFiledName].disable();
    } else {
      this.cashoutForm.controls[confirmFiledName].enable();
    }
  }
  bankValidator(input: AbstractControl) {
    return new Promise((resolve, reject) => {
      if (input.value.length !== 9) {
        resolve({ bankExist: true });
        return;
      } else {
        try {
          this.subscription.push(this.manageMoveService.lookupBankInfo(input.value)
            .subscribe(res => {
              if (res) {
                const bankData: BankInfo = <BankInfo>res;
                this.setBankName(bankData.name, bankData.abbr);
                resolve(null);
                return;
              } else {
                this.setBankName('', '');
                resolve({ bankExist: true });
                return;
              }
            }, err => {
              this.setBankName('', '');
              resolve({ bankExist: true });
              return;
          }));
        } catch (err) {
          this.setBankName('', '');
          resolve({ bankExist: true });
          return;
        }
      }
    });
  }

  removeChip() {
    this.cashoutForm.controls['RouteNo'].setValue('');
    this.setBankName('', '');
  }
  setBankName(full, abbr) {
    this.cashoutForm.controls['BankName'].setValue(full);
    this.cashoutForm.controls['BankShortName'].setValue(abbr);
  }

  /** mehtod to chage input type to text/password
   * @param input - input field name
  */
  maskInput(input: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
  }
  /** method to post the cashout details
   * @param - cashoutForm details
  */
  submitCashout() {
    this.spinner.show();
    this.cashoutModel = {} as Cashout;
    this.address = {} as Address;
    const street = this.cashoutForm.controls['Street'].value;
    const city = this.cashoutForm.controls['City'].value;
    const state = this.cashoutForm.controls['State'].value;
    const zip = this.cashoutForm.controls['ZIP'].value;
    let SocialSecurityNo = this.cashoutForm.controls['SocialSecurityNo'].value.trim() as string;
    SocialSecurityNo = `${SocialSecurityNo.substring(0, 3)}-${SocialSecurityNo.substring(3, 5)}-${SocialSecurityNo.substring(5, 9)}`;
    this.address.fullAddress = `${street}, ${city}, ${state}, ${zip}`;
    this.address.streetAddress = street;
    this.address.city = city;
    this.address.state = state;
    this.address.zipcode = zip;
    this.address.country = constants.country;
    this.cashoutModel.candidateId = this.transfereeDetails.candidateId;
    this.cashoutModel.accountHolder = this.cashoutForm.controls['AccountHolderName'].value.trim();
    this.cashoutModel.socialSecurityNumber = SocialSecurityNo;
    this.cashoutModel.routingNumber = this.cashoutForm.controls['RouteNo'].value.trim();
    this.cashoutModel.bankName = this.cashoutForm.controls['BankName'].value;
    this.cashoutModel.amount = this.transfereeDetails.flexSpendTotal;
    this.cashoutModel.accountNumber = this.cashoutForm.controls['AccountNo'].value.trim();
    this.cashoutModel.moveId = this.transfereeDetails.moveId;
    this.cashoutModel.mailingAddress = this.address;
    this.subscription.push(
      this.manageMoveService.saveCashoutDetails(this.cashoutModel).subscribe(
        response => {
          this.spinner.hide();
          if (response && (response.statusCode === '200' || response.statusCode === '204')) {
            this.transfereeDetails.cashedOutDate = this.dataPipe.transform(new Date(), constants.dateFormat);
            this.manageMoveSharedService.updateData(this.transfereeDetails);
            this.dialogRef.close(constants.cashoutSuccessResponseMsg);
          } else {
            this.dialogRef.close(constants.cashoutFailureResponseMsg);
          }
        }, err => {
          console.log('error in saving the detail');
        }
      )
    );
  }

  /**
   * Closing the dialog box - we are setting the form to empty
   */
  onNoClick(): void {
    this.dialogRef.close();
    this.initializeCashoutForm();
  }

  /**
   * Destroys the subscription object
   */
  ngOnDestroy() {
    this.subscription.forEach(a => a.unsubscribe());
  }
}
