import { DatePipe } from '@angular/common';
import { ManageMoveService } from './../../../core/services/manage-move.service';
import { Subscription } from 'rxjs';
import { RelocationBudgetDetails } from './../../../core/models/relocation-budget.model';
import { ManageMoveSharedService } from './../../../core/services/manage-move-shared.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild, OnDestroy, Inject } from '@angular/core';
import * as constants from '../../../core/models/constants';
import { LoggerService } from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';


export const errorMessage: { [key: string]: string } = {
  fullNameValidation: 'You must enter name',
  phoneNoValidation: 'You must enter valid phone number',
  splCharacterValidation: 'Special characters are not allowed.'
};

@Component({
  selector: 'app-get-rewards',
  templateUrl: './get-rewards.component.html',
  styleUrls: ['./get-rewards.component.scss']
})
export class GetRewardsComponent implements OnInit, OnDestroy {

  /** Title */
  title = 'Real Estate Rewards';

  /** Form group name */
  getRewardsForm: FormGroup;

  /** Error message */
  errors = errorMessage;
  canGetRewards: boolean = true;

  /** candidate Data */
  candidateInfo: RelocationBudgetDetails;

  clientContactId = '5d8b16401c9d440000f9bdec';
  private readonly subscription: Subscription = new Subscription();

  constructor(private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GetRewardsComponent>,
    private readonly manageMoveSharedService: ManageMoveSharedService,
    private readonly manageMoveService: ManageMoveService,
    private readonly datePipe: DatePipe,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.getRewardsForm = this.formBuilder.group({
      FullName: ['', Validators.required, this.noEmptySpaceValidator],
      PhoneNumber: ['', [Validators.required, Validators.minLength(10),
      this.regexValidator(new RegExp(/[a-zA-Z]/g), { 'alphabets': true }),
      this.regexValidator(new RegExp(/[!@#$%^&*()/\\?,.?":{}\-\+=_|<>;'`~\] ]/g), { 'splcharacter': true })]]
    });
    this.subscription.add(this.manageMoveSharedService.candidateDetails.subscribe(data => this.candidateInfo = data));
    if (this.candidateInfo) {
      const name = (this.candidateInfo.fullName).split(', ');
      this.getRewardsForm.controls['FullName'].setValue(`${name[name.length - 1]} ${name[0]}`);
      this.getRewardsForm.controls['PhoneNumber'].setValue(this.candidateInfo.contactNumber);
    }
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-GET_REWARDS', 'GET_REWARDS');
      });
  }

  canSubmitGetRewards() {
    if (this.data !== null) {
      this.canGetRewards = this.data['canGetRewards'];
    }
    return this.getRewardsForm.valid && this.canGetRewards;
  }

  /**
   * used to check wether input feild has empty space
   * @param input form with required fields
   */
  noEmptySpaceValidator(input: AbstractControl): ValidationErrors | null {
    return new Promise((resolve, reject) => {
      if (input.value.trim() === '') {
        resolve({ noEmptySpace: true });
      } else {
        resolve(null);
      }

    });
  }

  /**
   * Regex validation.Return true if value matches with given regex
   * @param regex Regular expression
   * @param error instence of ValidationErrors
   */
  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value.match(regex);
      return valid ? error : null;
    };
  }
  /**
   * save the referal details
   */
  submitResponse() {
    const rewardDetails = {
      candidateId: this.candidateInfo.candidateId,
      clientContactId: this.clientContactId,
      isAuthorized: this.candidateInfo.isAuthorized,
      contactName: this.getRewardsForm.controls['FullName'].value,
      contactNumber: this.getRewardsForm.controls['PhoneNumber'].value
    };
    this.subscription.add(this.manageMoveService.saveRewardDetails
      (rewardDetails).subscribe(
        response => {
          if (response && response.statusCode === 200) {
            this.candidateInfo.rewardRequestedDate = this.datePipe.transform(new Date(), constants.dateFormat);
            this.manageMoveSharedService.updateData(this.candidateInfo);
            this.onClose(constants.rewardsSuccessResponseMsg);
          } else {
            this.onClose(constants.rewardsFailureResponseMsg);
          }
        }));
  }

  /**
   * Closing the dialog box - we are setting the form to empty
   */
  onClose(response: string): void {
    this.dialogRef.close(response);
  }

  /**
   * Destroys the subscription object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
