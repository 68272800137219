/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./app.component";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/platform-browser";
import * as i6 from "ngx-cookie-service";
import * as i7 from "./core/services/app-config.service";
import * as i8 from "./core/services/authentication.service";
import * as i9 from "./core/services/loggedin-user-details.service";
import * as i10 from "./core/services/candidateneeds-assessment.service";
import * as i11 from "./core/services/candidateneeds-assessment-shared.service";
import * as i12 from "@angular/material/snack-bar";
import * as i13 from "./core/services/permissions.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["id", "skiptocontent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["href", "#maincontent"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Skip to main content"])), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["document", "mousemove"], ["document", "keypress"]], function (_v, en, $event) { var ad = true; if (("document:mousemove" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseMove($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keypress" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onKeyPress($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppComponent, [i4.MatIconRegistry, i5.DomSanitizer, i6.CookieService, i7.AppConfigService, i2.Router, i8.AuthenticationService, i9.LoggedInUserService, i10.CandidateNeedsAssessmentService, i11.CandidateNeedsAssessmentSharedService, i1.NgZone, i12.MatSnackBar, i13.PermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i3.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
