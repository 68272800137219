
<div class="transferre-dialogMainContainer main-butcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <h2 class="transferre-dialogtitle">Log Out?</h2>
    <button class="close-icon-color" tabindex="0" aria-label="Close Button"  href="javascript:void(0)" (click)="onClose()">
      <mat-icon aria-hidden="false">close</mat-icon>
    </button>
  </div>
  
  <mat-dialog-content class="middle-section">    
    <h3 class="confirmation-content">
      Please be aware that if you log out, responses to the questionnaire will not be saved.
    </h3>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="end">
    <div class="transferre-dialog-btn-container" fxLayoutGap="0.5em" fxLayoutAlign="end">
      <button type="button" class="transferee-text-button" id="cancel_cost_model" (click)="onClose()">CANCEL</button>
      <button type="button" class="transferee-contained-button transferee-dialog-contained-button"
        (click)="logout()">Log Out</button>
    </div>
  </mat-dialog-actions>
</div>