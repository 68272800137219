var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../../../environments/environment';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "./app-config.service";
export class AuthenticationService {
    constructor(appConfig) {
        this.appConfig = appConfig;
        const authOpts = {
            clientId: `${environment.oktaClientId}`,
            issuer: `${environment.oktaUrl}`,
            authorizeUrl: `${environment.oktaUrl}/v1/authorize`,
            redirectUri: `${environment.oktaRedirectUri}`,
            postLogoutRedirectUri: `${environment.oktaRedirectUri}`,
            tokenManager: {
                storage: 'sessionStorage'
            }
        };
        this.authClient = new OktaAuth(authOpts);
    }
    refreshSession() {
        return this.authClient.token.getWithoutPrompt().then(refreshedToken => {
            console.log('getWithoutPrompt()', refreshedToken); // Leave this debug code in place
            return refreshedToken;
        }).catch(err => {
            console.error(err);
            return;
        });
    }
    /** Service call for login */
    login(user) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const data = {
                    username: user.email,
                    password: user.password
                };
                const transaction = yield this.authClient.signIn(data);
                const tokens = yield this.authClient.token.getWithoutPrompt({
                    responseType: 'code',
                    sessionToken: transaction.sessionToken
                });
                tokens.forEach(token => {
                    if (token.idToken) {
                        this.authClient.tokenManager.add('idToken', token);
                    }
                    if (token.accessToken) {
                        localStorage.setItem('token', token.accessToken);
                        this.authClient.tokenManager.add('accessToken', token);
                    }
                    return this.authClient;
                });
            }
            catch (err) {
                console.log(err);
                throw err;
            }
        });
    }
    isAuthenticated() {
        return __awaiter(this, void 0, void 0, function* () {
            // Checks if there is a current accessToken in the TokenManger.
            return this.authClient.tokenManager.get('accessToken');
        });
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AppConfigService)); }, token: AuthenticationService, providedIn: "root" });
