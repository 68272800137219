<div fxLayout="row" class="invitation">
  <div class="success-mark">
    <mat-icon class="success-icon" svgIcon="success-icon"></mat-icon>
  </div>
  <div fxLayout="column" class="sent">
    {{data}}</div>

  <div class="snack-icons">
    <mat-icon (click)="dismiss()" id="dismiss" class="loader-close-icon">close</mat-icon>
  </div>
</div>