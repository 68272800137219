<div>
    <span [innerHTML]="maskedString"></span>
    <span>

        <img *ngIf="!isRevealed" class="unmask-icon"
            src="../../../assets/images/candidate-assessment/visibility.svg" alt="unmask icon" (click)="reveal()">
        <img *ngIf="isRevealed" class="mask-icon"
            src="../../../assets/images/candidate-assessment/visibility-off.svg" alt="mask icon"
            (click)="reveal()">

    </span>
</div>
