import { Component, OnInit, Inject } from '@angular/core';

import { highValueTitle, editHighValueTitle } from 'src/app/core/models/constants';
import { HighValueService } from 'src/app/core/services/high-value.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/core/models/error-state-matcher';
import { HighValueGoods } from 'src/app/core/models/high-value-goods.model';
import { LoggedInUserService } from 'src/app/core/services/loggedin-user-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { HighValueItemAPIResponse } from 'src/app/core/models/high-value-item-response.model';
import * as _ from 'lodash';
import { PermissionsService } from 'src/app/core/services/permissions.service';

export const errorMessages: { [key: string]: string } = {
  Item: 'You must select type of Item',
  Desc: 'You must enter Description of Item',
  Amount: 'You must enter numeric replacement value'
};

export const itemHints: { [key: string]: string } = {
  category3: 'Items of this type must be valued over 2,500 USD',
  category2: 'Items of this type must be valued over 5,000 USD',
  category1: 'Items of this type must be valued over 10,000 USD'
};

@Component({
  selector: 'app-high-value-modal',
  templateUrl: './high-value-modal.component.html',
  styleUrls: ['./high-value-modal.component.scss']
})
export class HighValueModalComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  highValueItem: FormGroup;
  highValueTitle = highValueTitle;
  editHighValueTitle = editHighValueTitle;
  errors = errorMessages;
  items: any;
  enumeratedItemArray: Array<any> = [];
  category1: [];
  category2: [];
  category3: [];
  itemHint = itemHints;
  itemHintValue = '';
  values = '';
  maxChars = 50;
  role = '';
  requestedHighValueGoods: HighValueGoods;
  userID: any;
  Amount: any = this.errors['Amount'];
  isEdit: Boolean = false;
  replacementValueRegEx = RegExp('^[0-9]*$');
  canUpdateHVGItem = true;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
    private highValueService: HighValueService,
    public dialogRef: MatDialogRef<HighValueModalComponent>,
    private readonly formBuilder: FormBuilder,
    public spinner: NgxSpinnerService,
    private readonly notificationsService: NotificationsService,
    private readonly loggedInUserService: LoggedInUserService,
    private readonly permissionsSvc: PermissionsService) {
    this.highValueItem = this.formBuilder.group({
      Item: ['', Validators.required],
      Amount: ['', Validators.compose([
        Validators.required,
        this.noWhitespaceValidator
      ])],
      Desc: ['', Validators.required],
      ID: ['']
    });
  }

  ngOnInit() {
    this.canUpdateHVGItem = this.permissionsSvc.getCapabilities();
    this.spinner.show();
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        this.userID = response.userId ? response.userId : '';
      });
    this.highValueService.getHighValueItemCategories().subscribe(res => {
      this.spinner.hide();
      this.category1 = res['Category 1'];
      this.category2 = res['Category 2'];
      this.category3 = res['Category 3'];
      this.enumeratedItemArray = this.enumeratedItemArray.concat(this.category1, this.category2, this.category3);
      this.enumeratedItemArray = _.sortBy( this.enumeratedItemArray, 'value' );
      if (this.data) {
        this.isEdit = true;
        this.highValueItem.get('Item').setValue(this.data.itemType);
        this.highValueItem.get('Amount').setValue(this.data.replacementValue);
        this.highValueItem.get('Desc').setValue(this.data.description);
        this.highValueItem.get('ID').setValue(this.data.serialNum);
        this.addTheTypeHint(this.data.itemType);
      }
    });
  }

  onSelectItem(formControlName) {
    const typeItem = this.highValueItem.get(formControlName).value;
    this.addTheTypeHint(typeItem);
  }

  addTheTypeHint(typeItem) {
    const group = this.enumeratedItemArray.find(x => x.value == typeItem).group;
    if (group === 'Category 3') {
      this.itemHintValue = this.itemHint['category3'];
    }
    if (group === 'Category 2') {
      this.itemHintValue = this.itemHint['category2'];
    }
    if (group === 'Category 1') {
      this.itemHintValue = this.itemHint['category1'];
    }
  }

  onNoClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }

  checkForAddItem() {
    return this.highValueItem.valid && this.canUpdateHVGItem;
  }

  checkItemSelected() {
    return this.highValueItem.get('Item').valid;
  }

  checkChar() {
    return this.highValueItem.get('Desc').valid;
  }

  onKey(event: any) { // without type info
    this.values = event.target.value;
    this.values = this.values.replace(/[,]/g, '');

    let typeItem = this.highValueItem.get('Item').value;
    let group = typeItem !== "" ? this.enumeratedItemArray.find(x => x.value === typeItem).group : "";
    if (group === 'Category 3') {
      if (parseInt(this.values) < 2500) {
        this.Amount = this.itemHint['category3'];
        this.highValueItem.get('Amount').setErrors({ 'incorrect': true });
      } else {
        this.highValueItem.get('Amount').setErrors(null);
      }
    }
    if (group === 'Category 2') {
      if (parseInt(this.values) < 5000) {
        this.Amount = this.itemHint['category2'];
        this.highValueItem.get('Amount').setErrors({ 'incorrect': true });
      } else {
        this.highValueItem.get('Amount').setErrors(null);
      }
    }
    if (group === 'Category 1') {
      if (parseInt(this.values) < 10000) {
        this.Amount = this.itemHint['category1'];
        this.highValueItem.get('Amount').setErrors({ 'incorrect': true });
      } else {
        this.highValueItem.get('Amount').setErrors(null);
      }
    }
    if (this.values === null || this.values === '' || !this.replacementValueRegEx.test(this.values)) {
      this.Amount = this.errors['Amount'];
      this.highValueItem.get('Amount').setErrors({ 'incorrect': true });
    }

  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

   addItem() {
    this.requestedHighValueGoods = {} as HighValueGoods;
    this.requestedHighValueGoods.candidateId = this.userID;
    this.requestedHighValueGoods.description = this.highValueItem.get('Desc').value;
    this.requestedHighValueGoods.itemType = this.highValueItem.get('Item').value;
    this.requestedHighValueGoods.replacementValue = this.highValueItem.get('Amount').value;
    this.requestedHighValueGoods.serialNum = this.highValueItem.get('ID').value ? this.highValueItem.get('ID').value : '';
    this.dialogRef.close(this.requestedHighValueGoods);
  }

  updateItem() {
    this.requestedHighValueGoods = {} as HighValueGoods;
    this.requestedHighValueGoods.candidateId = this.userID;
    this.requestedHighValueGoods.description = this.highValueItem.get('Desc').value;
    this.requestedHighValueGoods.itemType = this.highValueItem.get('Item').value;
    this.requestedHighValueGoods.replacementValue = this.highValueItem.get('Amount').value;
    this.requestedHighValueGoods.serialNum = this.highValueItem.get('ID').value ? this.highValueItem.get('ID').value : '';
    this.requestedHighValueGoods._id = this.data._id;
    this.dialogRef.close(this.requestedHighValueGoods);
  }

}
