import { PersistenceService, StorageType } from 'angular-persistence';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors,
  ValidatorFn,
  AbstractControl
} from '@angular/forms';
import { CandidateNeedsAssessment } from '../../../../core/models/candidateneeds-assessment.model';
import * as constants from '../../../../core/models/constants';
import { Address } from '../../../../core/models/address.model';
import { LocationService } from '../../../../core/services/location.service';
import {
  switchMap,
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import { MyErrorStateMatcher } from '../../../../core/models/error-state-matcher';
import { of } from 'rxjs';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';
import { CandidateNeedsAssessmentSharedService } from '../../../../core/services/candidateneeds-assessment-shared.service';


/**
 * stores the error messages to be displayed
 */
export const errorMessages: { [key: string]: string } = {
  Street: 'You must enter Street address',
  Town: 'You must enter Town address',
  State: 'You must enter State name',
  ZIP: 'You must enter ZIP code',
  City: 'You must select city',
  DestinationAddress: 'You must enter Destination Address',
  AddressNotFound: 'Results not found'
};
@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddressDetailsComponent implements OnInit, AfterViewInit {
  /** IVariable to error state*/
  matcher = new MyErrorStateMatcher();
  /**
   * stores the candidate details
   */
  @Input() needsAssessment: CandidateNeedsAssessment;

  @Input() step: number;
  /**
   * It emits the candidate details
   */
  @Output() notify: EventEmitter<CandidateNeedsAssessment> = new EventEmitter<
    CandidateNeedsAssessment
  >();
  /**
   * Back button to be focused first
   */
  @ViewChild('backButton', { static: false }) backButton: ElementRef;
  /**
   * Emitting the previous page step from child to parent
   */
  @Output() previousStep: EventEmitter<number> = new EventEmitter<number>();
  /**
   * property to store the error messages
   */
  errors = errorMessages;
  /**
   * Form to capture current address fields
   */
  addressForm: FormGroup;

  /**
   * Form to capture destination address fields
  */
  destinationAddressForm: FormGroup;

  /** Holds the List of Address Recived from API */
  cityList: Array<Address>;

  /** Default Address */
  defaultCity = constants.addressDetailsDefaultCity;

  /** Used to set error based on Place API result */
  addressNotFound = false;
  /** flag for dest selected */
  isDestSelected: boolean;
  /** flag to check departure valid*/
  isDestinationValid: boolean;
  /** state list from contamt file*/
  stateList = constants.stateList;
  /**store details on load */
  needsAssessmentDetails: CandidateNeedsAssessment;
  /**
   * Base constructor
   */
  /**
   * @param formBuilder - Attribute for form elements
   * @param persistenceService - Instance PersistenceService
   * @param destFormBuilder - Property for FormBuilder
   * @param persistenceService - Property for PersistenceService
   */
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cityService: LocationService,
    private readonly destFormBuilder: FormBuilder,
    private readonly persistenceService: PersistenceService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService,
    private readonly needsSharedSvc: CandidateNeedsAssessmentSharedService) {
    // defining a form group with validators for address form
    this.addressForm = this.formBuilder.group({
      Address: ['', Validators.required, this.noEmptySpaceValidator],
      Town: ['', Validators.required, this.noEmptySpaceValidator],
      State: ['', Validators.required],
      ZIP: ['', Validators.required, this.noEmptySpaceValidator]
    });

    this.destinationAddressForm = this.destFormBuilder.group({
      Address: ['', Validators.required, this.noEmptySpaceValidator]
    });
  }

  /**
   * used to check wether input feild has empty space
   * @param input form with required fields
   */
  noEmptySpaceValidator(input: AbstractControl): ValidationErrors | null {
    return new Promise((resolve, reject) => {
      if (input.value && input.value.trim() === '') {
        resolve({ required: true });
      } else {
        resolve(null);
      }

    });
  }

  /**
   * Fetches the address form details if the value is selected
   */
  ngOnInit() {
    this.needsSharedSvc.candidateDetails.subscribe(data => {
      this.needsAssessmentDetails = data;
    });
    if (!this.needsAssessment.departureAddr) {
      this.needsAssessment.departureAddr = {} as Address;
    }
    if (!this.needsAssessment.destinationAddr) {
      this.needsAssessment.destinationAddr = {} as Address;
    }
    if (this.step === 3 && this.needsAssessment.departureAddr) {
      this.addressForm.patchValue({
        Address: this.needsAssessment.departureAddr.streetAddress,
        Town: this.needsAssessment.departureAddr.city,
        State: this.needsAssessment.departureAddr.state,
        ZIP: this.needsAssessment.departureAddr.zipcode
      });
    }

    if (this.step === 4) {
      if (this.needsAssessment.destinationAddr.city) {
        this.destinationAddressForm.patchValue({
          Address: `${this.needsAssessment.destinationAddr.city}, ${this.needsAssessment.destinationAddr.state}`
        });
      } else {
        this.destinationAddressForm.controls['Address'].setValue(
          `${this.needsAssessmentDetails.destinationAddr.city}, ${this.needsAssessmentDetails.destinationAddr.state}`,
        );
      }
    }
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-ADDRESS_DETAILS', 'ADDRESS_DETAILS');
      });
  }

  /**method to be called on destination input change */
  onDestInput(search) {
    this.isDestinationValid = true;
    of(search)
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(searchTerm => {
          if (searchTerm) {
            searchTerm = searchTerm.trim();
            this.isDestSelected = false;
            return this.cityService.getCities(searchTerm);
          }
        })
      )
      .subscribe(res => {
        this.cityList = res;
        if (this.cityList.length === 0 && this.cityList) {
          this.destinationAddressForm.controls['Address'].markAsTouched();
          this.destinationAddressForm.controls['Address'].setErrors({
            invalidAddress: true
          });
        }
      }
      );
    if (search && search.trim() === '') {
      this.cityList = [];
    }
  }

  /** on dest dropdown close */
  onDestinationSelectionClosed() {
    this.isDestinationValid = false;
    if (!(this.isDestSelected && this.destinationAddressForm.controls['Address'].value)) {
      this.destinationAddressForm.controls['Address'].setErrors({
        invalidAddress: true
      });
    }
    this.cityList = [];
  }

  /** on dest selection */
  onDestinationSelected() {
    this.isDestSelected = true;
    this.cityList = [];
  }
  /**method to be called on keydown event for destination */
  onDestTabOut() {
    this.destinationAddressForm.controls['Address'].markAllAsTouched();
    this.onFocusout();
  }

  /** Method to check address validation on blur event */
  onFocusout() {
    if (this.destinationAddressForm.controls['Address'].dirty) {
      this.isDestinationValid = false;
      if (!(this.isDestSelected && this.destinationAddressForm.controls['Address'].value)) {
        this.destinationAddressForm.controls['Address'].setErrors({
          invalidAddress: true
        });
      }
    }
  }
  /**
   * Store the current step in session
   */
  ngAfterViewInit(): void {
    this.persistenceService.set('currentStep', JSON.stringify(this.step),
      { type: StorageType.SESSION });
    setTimeout(() => {
      this.backButton.nativeElement.focus();
    }, 1000);
  }

  /*
   * used to submit the current address of the candidate
   */
  onSubmit() {
    if (this.step === 3) {
      this.needsAssessment.departureAddr.streetAddress = this.addressForm.controls.Address.value.trim();
      this.needsAssessment.departureAddr.city = this.addressForm.controls.Town.value.trim();
      this.needsAssessment.departureAddr.state = this.addressForm.controls.State.value.trim();
      this.needsAssessment.departureAddr.zipcode = this.addressForm.controls.ZIP.value.trim();
      this.needsAssessment.departureAddr.fullAddress = `${this.needsAssessment.departureAddr.streetAddress}, ` +
        `${this.needsAssessment.departureAddr.city}, ` +
        `${this.needsAssessment.departureAddr.state}, ` +
        `${this.needsAssessment.departureAddr.zipcode}`;
      this.emit();
    }

    if (this.step === 4) {
      this.needsAssessment.destinationAddr.fullAddress = this.destinationAddressForm.controls.Address.value;
      const completAddr = this.destinationAddressForm.controls.Address.value.split(
        ','
      );
      this.needsAssessment.destinationAddr.city = completAddr[0];
      this.needsAssessment.destinationAddr.state = completAddr[1];
      this.needsAssessment.destinationAddr.country = '';
      this.needsAssessment.destinationAddr.streetAddress = '';
      this.needsAssessment.destinationAddr.zipcode = '';
      this.emit();
    }
  }
  /**emits the data to parent component */
  emit() {
    if (this.step > this.needsAssessment.completedStep) {
      this.needsAssessment.completedStep = this.step;
    }
    this.notify.emit(this.needsAssessment);
  }

  /**
   * To emit the previous step value to parent
   */
  previousEmit() {
    this.previousStep.emit(this.step - 1);
  }
}
