import { PersistenceService, StorageType } from 'angular-persistence';
import { Component, OnInit, EventEmitter, Output, Input, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import {
  CandidateNeedsAssessment,
  FamilyDetails
} from '../../../../core/models/candidateneeds-assessment.model';
import * as constants from '../../../../core/models/constants';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';

/**
 * Base component for Relocation-Details
 */
@Component({
  selector: 'app-relocation-details',
  templateUrl: './relocation-details.component.html',
  styleUrls: ['./relocation-details.component.scss']
})
export class RelocationDetailsComponent implements OnInit, AfterViewInit {
  /**
   * Getting candidate details as input from parent component
   */
  @Input() needsAssessment: CandidateNeedsAssessment;
  /**
   * Emitting the updated candidate details value from child to parent
   */
  @Output() notify: EventEmitter<CandidateNeedsAssessment> = new EventEmitter<
    CandidateNeedsAssessment
  >();

  /**
   * Back button to be focused first
   */
  @ViewChild('backButton', {static : false}) backButton: ElementRef;
  /**
   * Emitting the previous page step from child to parent
   */
  @Output() previousStep: EventEmitter<number> = new EventEmitter<number>();

  @Input() step: number;
  /**
   * Used to disable the Next button by default
   */
  enablePeopleStepButton = false;
  enableAddressStepButton = false;

  relocationRelocYes = constants.relocationRelocYes;
  relocationRelocNo = constants.relocationRelocNo;
  peopleRelocationOnePerson = constants.peopleRelocationOnePerson;
  peopleRelocationTwoPeople = constants.peopleRelocationTwoPeople;
  peopleRelocationThreePeople = constants.peopleRelocationThreePeople;
  peopleRelocationFourPlusPeople = constants.peopleRelocationFourPlusPeople;
  /**
   * Base constructor
   * @param persistenceService Instance PersistenceService
   */
  constructor(private readonly persistenceService: PersistenceService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }
  /**
   * Intialising the selected values
   */
  ngOnInit() {
    if (!this.needsAssessment.familyDetails) {
      this.needsAssessment.familyDetails = {} as FamilyDetails;
    }
    if (this.step === 1) {
      if (this.needsAssessment.familyDetails.familyRelocationStatus) {
        this.enablePeopleStepButton = true;
      } else {
        this.enablePeopleStepButton = false;
      }
    } else {
      if (this.needsAssessment.familyDetails.noOfRelocatePeople) {
        this.enableAddressStepButton = true;
      } else {
        this.enableAddressStepButton = false;
      }
    }
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-RELOCATION_DETAILS', 'RELOCATION_DETAILS');
      });
  }
  /**
    * Store the current step in session
    */
  ngAfterViewInit(): void {
    this.persistenceService.set('currentStep', JSON.stringify(this.step),
      { type: StorageType.SESSION });
      setTimeout( () => {
        this.backButton.nativeElement.focus();
      }, 1000);
  }

  onValueSelection(selectedValue: string): void {
    if (this.step === 1) {
      this.needsAssessment.familyDetails.familyRelocationStatus = selectedValue;
      this.enablePeopleStepButton = true;
    } else {
      this.needsAssessment.familyDetails.noOfRelocatePeople = selectedValue;
      this.enableAddressStepButton = true;
    }
  }
  /**
   * To emit the value to parent
   */
  emit() {
    if (!this.needsAssessment.completedStep) {
      this.needsAssessment.completedStep = 1;
    } else if (this.step > this.needsAssessment.completedStep) {
      this.needsAssessment.completedStep = this.step;
    }
    this.notify.emit(this.needsAssessment);
  }
  /**
  * To emit the previous step value to parent
  */
  previousEmit() {
    this.previousStep.emit(this.step - 1);
  }
}
