import { DatePipe } from '@angular/common';
import { InvitationSentComponent } from './../invitation-sent/invitation-sent.component';
import { Component, OnInit, OnDestroy, Input, DoCheck } from '@angular/core';
import * as constants from '../../../../core/models/constants';
import { Subscription } from 'rxjs';
import { ScheduleMoveComponent } from '../../schedule-move/schedule-move.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CashoutComponent } from '../../cashout/cashout.component';
import { RelocationBudgetDetails } from '../../../../core/models/relocation-budget.model';
import { ManageMoveSharedService } from '../../../../core/services/manage-move-shared.service';
import { TimelineService } from '../../../../core/models/timeline-service.model';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';


@Component({
  selector: 'app-relocation-budget',
  templateUrl: './relocation-budget.component.html',
  styleUrls: ['./relocation-budget.component.scss']
})

export class RelocationBudgetComponent implements OnInit, OnDestroy {
  /** Recieving transferee Details from parent component */
  // @Input() transfereeDetails: RelocationBudgetDetails;
  transfereeDetails: RelocationBudgetDetails;
  /**used to store currency symbol */
  currencySymbol: string;
  /**Stores Chart labels */
  chartLabels: Array<string>;
  /**Stores chart data set */
  chartDataSetData: Array<number>;
  /**Stores chart border width */
  chartBorderWidth: number;
  /**Stores colors for data set */
  chartDataSetColor: Array<string>;
  /** Stores if the status of arcs */
  chartArcDisableStatus: Array<boolean>;
  /**used to store tittle for relocation budget*/
  RelocationBudgetTittle: string;
  /** Variable to store Professional VanLineMove label */
  ProfessionalVanLineMove: string;
  /** Variable to store recommended services label */
  recommendedServices: string;
  /** Variable to remaining label */
  remaining: string;
  /** Variable to store available budget percentage*/
  availableBudgetPercentage: number;
  /** Variable to % */
  percentage: string;
  /** Variable to store sheduleMyMoveTileTittle */
  sheduleMyMoveTileTittle: string;
  /** Variable to store  sheduleMyMoveTileContent*/
  sheduleMyMoveTileContent: string;
  /** Variable to store  sheduleMyMoveBtnContent*/
  sheduleMyMoveBtnContent: string;
  /** Variable to store cashOutTileTittle*/
  cashOutTileTittle: string;
  /** Variable to store cashOutTileTittleTileContent */
  cashOutTileTittleTileContent: string;
  /** Variable to store  cashOutTileTittleBtnContent*/
  cashOutTileTittleBtnContent: string;
  /** Variable to store currency */
  currency: string;
  /** Variable to store is vanline Percentage */
  vanlinePercent: number;
  /** Variable to store is cashoutPercentage */
  cashoutPercent: number;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** timeline Dates */
  timelineDetails: TimelineService[];
  /** schedule move display dates */
  scheduleMoveDates: TimelineService[] = [];
  /** client contact id */
  clientContactId = '5d8b16401c9d440000f9bdec';
  /** varibale to check is cashed out */
  isCashedout: boolean;

  /**
   * Base Constructor
   * @param dialog Instance of MatDialog
   */
  constructor(
    public dialog: MatDialog,
    private readonly manageMoveSharedService: ManageMoveSharedService,
    private readonly snackBar: MatSnackBar,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService,
    private readonly datePipe: DatePipe) { }

  /**Intilising the method */
  ngOnInit() {
    this.subscription.add(this.manageMoveSharedService.candidateDetails.subscribe(resp => {
      if (resp) {
        this.transfereeDetails = resp;
        this.transfereeDetails.cashedOutDate = this.datePipe.transform(this.transfereeDetails.cashedOutDate, constants.dateFormat);
        if (this.transfereeDetails.ScheduledMoveDate) {
          this.setChartColorAndBudgetPercentage();
        }
      }
    }));
    if (this.transfereeDetails.cashedOutDate) {
      this.isCashedout = true;
    }
    this.RelocationBudgetTittle = constants.relocationBudgetTitle;
    this.ProfessionalVanLineMove = constants.ProfessionalVanLineMove;
    this.recommendedServices = constants.recommendedServices;
    this.remaining = constants.remaining;
    this.percentage = constants.percentage;
    this.sheduleMyMoveTileTittle = constants.sheduleMyMoveTileTittle;
    this.sheduleMyMoveTileContent = constants.sheduleMyMoveTileContent;
    this.sheduleMyMoveBtnContent = constants.sheduleMyMoveBtnContent;
    this.cashOutTileTittle = constants.cashOutTileTittle;
    this.cashOutTileTittleTileContent = constants.cashOutTileTittleTileContent;
    this.cashOutTileTittleBtnContent = constants.cashOutTileTittleBtnContent;
    this.currencySymbol = constants.currency;
    this.setChartColorAndBudgetPercentage();
    this.setScheduleMoveTile();
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-RELOCATION_BUDGET', 'RELOCATION_BUDGET');
      });
  }
  /**
   * set setChartColor and available budget percentage
   */
  setChartColorAndBudgetPercentage() {
    this.vanlinePercent = Math.round((this.transfereeDetails.coreBudgetAmount * 100) / this.transfereeDetails.authorizedAmt);
    this.cashoutPercent = Math.round((this.transfereeDetails.flexSpendTotal * 100) / this.transfereeDetails.authorizedAmt);
    this.chartLabels = [this.recommendedServices, this.ProfessionalVanLineMove];
    this.chartDataSetData = [this.cashoutPercent, this.vanlinePercent];
    this.chartBorderWidth = 2;
    if (this.transfereeDetails.ScheduledMoveDate === null && this.transfereeDetails.cashedOutDate === null) {
      this.availableBudgetPercentage = 100;
      this.chartDataSetColor = [`#6192D3`, `#40806A`];
      this.chartArcDisableStatus = [false, false];
    } else if (this.transfereeDetails.ScheduledMoveDate !== null && this.transfereeDetails.cashedOutDate === null) {
      this.availableBudgetPercentage = this.cashoutPercent;
      this.chartDataSetColor = [`#6192D3`, `#B8D8CD`];
      this.chartArcDisableStatus = [false, true];
    } else if (this.transfereeDetails.ScheduledMoveDate === null && this.transfereeDetails.cashedOutDate !== null) {
      this.availableBudgetPercentage = this.vanlinePercent;
      this.chartDataSetColor = [`#D0DDED`, `#40806A`];
      this.chartArcDisableStatus = [true, false];
    } else if (this.transfereeDetails.ScheduledMoveDate !== null && this.transfereeDetails.cashedOutDate !== null) {
      this.availableBudgetPercentage = 0;
      this.chartDataSetColor = [`#D0DDED`, `#B8D8CD`];
      this.chartArcDisableStatus = [true, true];
    }
  }

  /**
   * Loads the ScheduleMove Component in MatDialog
   */
  openDialog(componentName: string): void {
    if (componentName === 'SheduleMove') {
      const dialogRef = this.dialog.open(ScheduleMoveComponent, {
        panelClass: 'schedule-dialogMainContainer',
        autoFocus: false,
        data: this.transfereeDetails
      });
      dialogRef.afterClosed()
        .subscribe((response: boolean) => {
          if (response) {
            this.setChartColorAndBudgetPercentage();
            this.snackBar.openFromComponent(InvitationSentComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              data: `Request Sent`,
              duration: 5000
            });
          }
        });
    } else if (componentName === 'CashOut') {
      const dialogRef = this.dialog.open(CashoutComponent, {
        panelClass: 'cashout-dialogMainContainer',
        autoFocus: false,
        data: this.transfereeDetails
      });
      this.subscription.add(dialogRef.afterClosed().subscribe(response => {
        if (response) {
          if (response === constants.cashoutSuccessResponseMsg) {
            this.isCashedout = true;
          }
          this.snackBar.openFromComponent(InvitationSentComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            data: response,
            duration: 5000
          });
        }
      }));
    }
  }

  /** set data for schedule move tile */
  setScheduleMoveTile() {
    this.timelineDetails = this.transfereeDetails.timeLineDates;
    this.timelineDetails = this.timelineDetails.filter(
      (timelineService) => (timelineService.sortOrder > 0 && timelineService.serviceType !== 'others')
    );
    this.timelineDetails.sort(
      (firstData, secondData) => (firstData.sortOrder > secondData.sortOrder) ? 1 : -1
    );
    this.timelineDetails.forEach(moveService => {
      if (moveService.isActualAvailable && moveService.serviceType === 'estimated') {
        return;
      } else if (
        !moveService.isActualAvailable &&
        moveService.serviceType === 'estimated' &&
        new Date(moveService.startDate).toISOString().substring(0, 10) >= new Date().toISOString().substring(0, 10)
      ) {
        this.scheduleMoveDates.push(moveService);
      } else if (new Date(moveService.endDate).toISOString().substring(0, 10) >= new Date().toISOString().substring(0, 10)) {
        this.scheduleMoveDates.push(moveService);
      }
    });
  }

  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
