import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { CandidateNeedsAssessment } from 'src/app/core/models/candidateneeds-assessment.model';
import * as constants from '../../../../core/models/constants';
import { PersistenceService, StorageType } from 'angular-persistence';
import { LoggerService } from 'src/app/core/services/logger.service';
import { LoggedInUserService } from 'src/app/core/services/loggedin-user-details.service';

@Component({
  selector: 'app-high-value-goods-details',
  templateUrl: './high-value-goods-details.component.html',
  styleUrls: ['./high-value-goods-details.component.scss']
})
export class HighValueGoodsDetailsComponent implements OnInit {

  /**
   * Getting candidate details as input from parent component
   */
  @Input() needsAssessment: CandidateNeedsAssessment;
  /**
   * Emitting the updated candidate details value from child to parent
   */
  @Output() notify: EventEmitter<CandidateNeedsAssessment> = new EventEmitter<CandidateNeedsAssessment>();

  /**
   * Back button to be focused first
   */
  @ViewChild('backButton', { static: false }) backButton: ElementRef;
  /**
   * Emitting the previous page step from child to parent
   */
  @Output() previousStep: EventEmitter<number> = new EventEmitter<number>();

  @Input() step: number;

  enableStepButton = false;
  highValueGoodsYes = constants.highValueGoodsYes;
  highValueGoodsNo = constants.highValueGoodsNo;

  constructor(private readonly persistenceService: PersistenceService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    if (!this.needsAssessment.highValueGoods) {
      this.needsAssessment.highValueGoods = '';
    }

    if (this.needsAssessment.highValueGoods) {
      this.enableStepButton = true;
    } else {
      this.enableStepButton = false;
    }

    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-HIGH-VALUE-GOODS_DETAILS', 'HIGH-VALUE-GOODS_DETAILS');
      });
  }

  /**
    * Store the current step in session
    */
  ngAfterViewInit(): void {
    this.persistenceService.set('currentStep', JSON.stringify(this.step),
      { type: StorageType.SESSION });
    setTimeout(() => {
      this.backButton.nativeElement.focus();
    }, 1000);
  }

  onValueSelection(selectedValue: string): void {
    this.needsAssessment.highValueGoods = selectedValue;
    this.enableStepButton = true;
  }

  /**emits the data to parent component */
  emit() {
    if (this.step > this.needsAssessment.completedStep) {
      this.needsAssessment.completedStep = this.step;
    }
    this.notify.emit(this.needsAssessment);
  }

  /**
   * To emit the previous step value to parent
   */
  previousEmit() {
    this.previousStep.emit(this.step - 1);
  }

}
