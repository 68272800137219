import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GetRewardsComponent } from './../get-rewards/get-rewards.component';
import { ManageMoveSharedService } from './../../../core/services/manage-move-shared.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import * as constants from './../../../core/models/constants';
import { MatDialog, MatSnackBar } from '@angular/material';
import { RelocationBudgetDetails } from './../../../core/models/relocation-budget.model';
import { InvitationSentComponent } from './../right-maincontent/invitation-sent/invitation-sent.component';
import { ManageMoveService } from './../../../core/services/manage-move.service';
import { LoggerService } from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';

export const errorMessage: { [key: string]: string } = {
  fullNameValidation: 'You must enter name',
  phoneNoValidation: 'You must enter valid phone number',
  splCharacterValidation: 'Special characters are not allowed.'
};

@Component({
  selector: 'app-rewards-details',
  templateUrl: './rewards-details.component.html',
  styleUrls: ['./rewards-details.component.scss']
})
export class RewardsDetailsComponent implements OnInit, OnDestroy {
  /** candidate Data */
  candidateInfo: RelocationBudgetDetails;
  /** Stores the static content used in the template*/
  templateString = constants.REWARDS_DETAILS_TEMPLATE_STRING;
  /** Subscription property for subscribing services */
  private readonly subscription: Subscription = new Subscription;
  /** Form group name */
  rewardsForm: FormGroup;
  /** Error message */
  errors = errorMessage;
  clientContactId = '5d8b16401c9d440000f9bdec';
  canGetRewards: any;

  constructor(private readonly formBuilder: FormBuilder,
    public dialog: MatDialog,
    private readonly manageMoveSharedService: ManageMoveSharedService,
    private readonly permissionsSvc: PermissionsService,
    public snackBar: MatSnackBar,
    private readonly datePipe: DatePipe,
    private readonly manageMoveService: ManageMoveService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.canGetRewards = this.permissionsSvc.getCapabilities();
    this.rewardsForm = this.formBuilder.group({
      FullName: ['', Validators.required, this.noEmptySpaceValidator],
      PhoneNumber: ['', [Validators.required, Validators.minLength(10),
      this.regexValidator(new RegExp(/[a-zA-Z]/g), { 'alphabets': true }),
      this.regexValidator(new RegExp(/[!@#$%^&*()/\\?,.?":{}\-\+=_|<>;'`~\] ]/g), { 'splcharacter': true })]]
    });
    this.subscription.add(this.manageMoveSharedService.candidateDetails.subscribe(data => this.candidateInfo = data));
    if (this.candidateInfo) {
      const name = (this.candidateInfo.fullName).split(', ');
      this.rewardsForm.controls['FullName'].setValue(`${name[1]} ${name[0]}`);
      this.rewardsForm.controls['PhoneNumber'].setValue(this.candidateInfo.contactNumber);
    }
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-REWARD_DETAILS', 'REWARD_DETAILS');
      });
  }



  canSubmitGetRewards() {
    return this.rewardsForm.valid && this.canGetRewards;
  }

  /**
   * used to check wether input feild has empty space
   * @param input form with required fields
   */
  noEmptySpaceValidator(input: AbstractControl): ValidationErrors | null {
    return new Promise((resolve, reject) => {
      if (input.value.trim() === '') {
        resolve({ noEmptySpace: true });
      } else {
        resolve(null);
      }

    });
  }

  /**
   * Regex validation.Return true if value matches with given regex
   * @param regex Regular expression
   * @param error instence of ValidationErrors
   */
  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value.match(regex);
      return valid ? error : null;
    };
  }

  /**Function To open reward referal dialog on click of Referal button */
  public openDialog(): void {
    const dialogRef = this.dialog.open(GetRewardsComponent, {
      panelClass: 'schedule-dialogMainContainer',
      disableClose: false,
      data: { canGetRewards: this.canGetRewards }
    });

    this.subscription.add(dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (constants.rewardsSuccessResponseMsg === response) {
          this.candidateInfo.rewardRequestedDate = this.datePipe.transform(new Date(), constants.dateFormat);
        }
        this.showNotification(response);
      }
    }));
  }

  /**
     * save the referal details
     */
  submitResponse() {
    const rewardDetails = {
      candidateId: this.candidateInfo.candidateId,
      clientContactId: this.clientContactId,
      isAuthorized: this.candidateInfo.isAuthorized,
      contactName: this.rewardsForm.controls['FullName'].value,
      contactNumber: this.rewardsForm.controls['PhoneNumber'].value
    };
    this.subscription.add(this.manageMoveService.saveRewardDetails
      (rewardDetails).subscribe(
        response => {
          if (response && response.statusCode === 200) {
            this.candidateInfo.rewardRequestedDate = this.datePipe.transform(new Date(), constants.dateFormat);
            this.manageMoveSharedService.updateData(this.candidateInfo);
            this.showNotification(constants.rewardsSuccessResponseMsg);
          } else {
            this.showNotification(constants.rewardsFailureResponseMsg);
          }
        }));
  }
  /** method to show the notification message */
  showNotification(message: string): void {
    this.snackBar.openFromComponent(InvitationSentComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: message,
      duration: 5000
    });
  }

  /**
   * Destroys the subscription object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
