import {
  Component,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { MatStepper } from '@angular/material';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PersistenceService, StorageType } from 'angular-persistence';
import { CandidateNeedsAssessment } from '../../core/models/candidateneeds-assessment.model';
import { StepHelp } from '../../core/models/step-help.model';
import * as constants from '../../core/models/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { CandidateNeedsAssessmentService } from '../../core/services/candidateneeds-assessment.service';
import { CandidateNeedsAssessmentSharedService } from '../../core/services/candidateneeds-assessment-shared.service';
import { LoggerService } from '../../core/services/logger.service';
import { LoggedInUserService } from '../../core/services/loggedin-user-details.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmLogOutComponent } from './confirm-log-out/confirm-log-out.component';
/**
 * Base component for landing page to include all the components
 */
@Component({
  selector: 'app-candidate-assessment',
  templateUrl: './candidate-assessment.component.html',
  styleUrls: ['./candidate-assessment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CandidateAssessmentComponent implements OnInit, OnDestroy {

  /**
   * Used to store candidate details
   */
  assessmentModel: CandidateNeedsAssessment = {} as CandidateNeedsAssessment;
  /**
   * Used to inject MatStepper
   */
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  /**
   * Used in right section
   */
  slideLength: number;
  /**
   * Subscription prop for unsubscribing services
   */
  private readonly subscription: Subscription = new Subscription();

  CandidateName = 'mobilify';

  // Help hint for the current step
  displayText: StepHelp;

  // Right pane content for each step as an array
  messages: StepHelp[] = constants.stepHelp;
  // current stepper id
  steppingId: number;

  /**
   * Base constructor
   * @param dialog instance of MatDialog
   * @param _formBuilder Formbuilder variable
   * @param location location service
   * @param activatedRoute To route to the particular location
   */
  constructor(
    public dialog: MatDialog,
    private readonly _formBuilder: FormBuilder,
    private readonly location: Location,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly persistenceService: PersistenceService,
    private readonly spinner: NgxSpinnerService,
    private readonly candidateService: CandidateNeedsAssessmentService,
    private readonly needsAssessmentSvc: CandidateNeedsAssessmentSharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService

  ) {
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (event.url === event.urlAfterRedirects) {
          if (
            this.persistenceService.get('needsAssesment', StorageType.SESSION)
          ) {
            const savedModel = JSON.parse(
              this.persistenceService.get('needsAssesment', StorageType.SESSION)
            );
            this.assessmentModel = savedModel;
          }
        }
      });
  }
  /**
   * Intialising the selected values
   */
  ngOnInit() {

    this.candidateService.getCandidateNeedsAssessment().subscribe((resp: CandidateNeedsAssessment) => {
      this.needsAssessmentSvc.updateCandidateNeedsAssesment(resp);
      this.spinner.hide();
      if (resp.familyDetails.noOfRelocatePeople) {
        this.router.navigate(['/manage-move']);
      }
    }, err => {
      this.spinner.hide();
    });

    let currentStep;
    this.assessmentModel.completedStep = this.assessmentModel.completedStep
      ? this.assessmentModel.completedStep
      : 0;
    this.subscription.add(
      this.activatedRoute.params.subscribe(params => {
        if (!params['id']) {
          this.changeStep(0);
          this.persistenceService.set('currentStep', JSON.stringify(0), { type: StorageType.SESSION });
        } else {
          currentStep = parseInt(this.persistenceService.get('currentStep', StorageType.SESSION), 10);
          this.steppingId = parseInt(params['id'], 10);
          if (currentStep === this.steppingId) {
            this.changeStep(this.steppingId);
          } else {
            if (!this.assessmentModel.familyDetails) {
              this.changeStep(1);
            } else if (this.assessmentModel.familyDetails.familyRelocationStatus === constants.relocationRelocYes &&
              (!this.assessmentModel.familyDetails.noOfRelocatePeople ||
                (this.assessmentModel.familyDetails.noOfRelocatePeople &&
                  this.assessmentModel.familyDetails.noOfRelocatePeople === '1'))) {
              this.changeStep(2);
            } else if (this.assessmentModel.familyDetails.familyRelocationStatus === constants.relocationRelocNo &&
              (this.assessmentModel.completedStep + 1) === 2) {
              this.changeStep(this.assessmentModel.completedStep + 2);
            } else {
              this.changeStep(this.assessmentModel.completedStep + 1);
            }
          }
        }
      })
    );
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-CANDIDATE_ASSESSMENT', 'CANDIDATE_ASSESSMENT');
      });
  }
  /**
   * To set the selected index
   * @param event - selected index
   */
  public onStepChange(event: any): void {
    const stepVal = '/candidate-assessment/step/' + event.selectedIndex;
    this.location.go(stepVal);
  }

  /**Function to open modal Dialog to give warning before logout*/
  logout() {
    const dialogRef = this.dialog.open( ConfirmLogOutComponent, {
      panelClass: 'dialogMainContainer',
      data: {},
    });
    this.subscription.add(dialogRef.afterClosed().subscribe(response => {
      if (response === 'logout') {
        this.router.navigate(['logout']);
      }
    }));
  }

  /**
   * Notify User
   * @param message - parent message
   */
  onNotify(model: CandidateNeedsAssessment): void {
    this.assessmentModel = model;
    if (
      this.stepper.selectedIndex === 2 &&
      this.assessmentModel.familyDetails.familyRelocationStatus === 'No'
    ) {
      this.assessmentModel.familyDetails.noOfRelocatePeople = '1';
      this.changeStep(3);
    } else if (this.assessmentModel.familyDetails.familyRelocationStatus === 'Yes' &&
      this.assessmentModel.familyDetails.noOfRelocatePeople === '1') {
      this.assessmentModel.familyDetails.noOfRelocatePeople = '';
    }
    this.displayText = this.getNextStepMessage(this.stepper.selectedIndex);
    this.persistenceService.set(
      'needsAssesment',
      JSON.stringify(this.assessmentModel),
      { type: StorageType.SESSION }
    );
  }

  /**
   * Used to go to given index
   * @param index selected index
   */
  changeStep(index: number): void {
    this.stepper.selectedIndex = index;
    this.displayText = this.getNextStepMessage(index);
    const stepVal = '/candidate-assessment/step/' + index;
    this.location.go(stepVal);
  }

  /**
   * previous Step
   * @param previousStepIndex - previous stepper index
   */
  previousStep(previousStepIndex: number) {
    if (
      previousStepIndex === 2 &&
      this.assessmentModel.familyDetails.familyRelocationStatus === constants.relocationRelocNo
    ) {
      this.changeStep(1);
    } else {
      this.changeStep(previousStepIndex);
    }
  }

  getNextStepMessage(index: number): StepHelp {
    const message: StepHelp[] = this.messages.filter(x => x.step === index);
    return message[0];
  }
  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
